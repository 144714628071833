/**
 * @description LanguageSelector component.
 */

import React, { useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Dropdown from 'components/dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import {
    boxContainerStyle,
    titleStyle,
    boxStyle,
    descStyle,
    boxPreferredStyle,
    boxItemStyle,
    boxItemLanguageStyle,
    titlePreferredLngStyle,
    descPreferredLngStyle,
} from './LanguageSelector.style';
import { getLanguages } from 'services/profile/profileService';
import LocalStorageService from 'services/local-storage/localStorageService';

const LanguageSelector = () => {
    const theme = useTheme();
    const { t, i18n } = useTranslation();
    const [languages, setLanguages] = useState([]);
    const jwtToken = LocalStorageService.getJwtToken();
    const [defaultLanguage, setDefaultLanguage] = useState('');

    useEffect(() => {
        getLanguages(jwtToken).then((response) => {
            if (response) {
                const mappedData = response.map((lng) => {
                    return {
                        text: lng.language,
                        value: lng.code,
                    };
                });
                setLanguages(mappedData);
                setDefaultLanguage(i18n.language);
            }
        });
    }, []);

    return (
        <Box sx={boxContainerStyle}>
            <Box sx={boxStyle}>
                <Typography sx={titleStyle(theme)}>
                    {t('preferred-language.language')}
                </Typography>
                <Typography sx={descStyle(theme)}>
                    {t('preferred-language.manage-your-language-preferences')}
                </Typography>
            </Box>
            <Box sx={boxPreferredStyle}>
                <Box sx={boxItemStyle}>
                    <Typography sx={titlePreferredLngStyle(theme)}>
                        {t('preferred-language.title')}
                    </Typography>
                </Box>
                <Box sx={boxItemLanguageStyle}>
                    <Dropdown
                        options={languages}
                        onChangeValue={() => {}}
                        defaultValue={defaultLanguage}
                    />
                    <Typography sx={descPreferredLngStyle(theme)}>
                        {t('preferred-language.choose-the-language')}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default LanguageSelector;

