/**
 * @description PageTitle component.
 */

import React from 'react';
import resourceUrl from 'resourceResolver';
import { CustomSVG, boxContainerStyle, titleStyle } from './PageTitle.style';
import { Box, Typography, useTheme } from '@mui/material';
import { pageTitlePropTypes } from 'types/pageTitlePropTypes';
const arrowLeft = resourceUrl('icons/arrow-left.svg');

const PageTitle = ({ onClick, title, submit }) => {
    const theme = useTheme();
    return (
        <Box sx={boxContainerStyle}>
            <CustomSVG src={arrowLeft} onClick={onClick} submit={submit} />
            <Typography sx={titleStyle(theme, submit)}>{title}</Typography>
        </Box>
    );
};

PageTitle.propTypes = {
    ...pageTitlePropTypes,
};

export default PageTitle;

