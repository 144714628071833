/**
 * @description Define the expected data types for props passed to TabPanel component.
 */

import { PropTypes } from 'prop-types';

export const tabPanelPropTypes = {
    children: PropTypes.node,
    value: PropTypes.number,
    index: PropTypes.number,
};