import PageTitle from 'components/page-title/PageTitle';
import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import {
    boxContainerStyle,
    boxItemStyle,
    titleStyle,
    boxCardTypeStyle,
    arrowIconStyle,
    boxAccountInformation,
    boxBrandingStyle,
    boxAccountHolderName,
    cardTypeStyle,
    titleAccountHolderNameStyle,
    errorStyle,
    boxItemBrandingStyle,
    boxLoadingStyle,
} from './AddCardForm.style';
import { useTranslation } from 'react-i18next';
import Dropdown from 'components/dropdown/Dropdown';
import resourceUrl from 'resourceResolver';
import Button from 'components/button/Button';
import { useForm, Controller } from 'react-hook-form';
import { useAuth } from 'hooks/useAuth';
import Popup from 'components/pop-up/Popup';
import WalletService from 'services/wallet/walletService';
import { getAccountInfo } from 'services/profile/profileService';
import { getBranding } from 'services/payment/paymentService';
import Loading from 'components/loading/Loading';
import AddCardSuccessful from 'components/add-card-successful/AddCardSuccessful';
import { isSuccess } from 'constants/statusCode';
const arrowRight = resourceUrl('icons/arrow-right.svg');
const iconCard = resourceUrl('icons/card.svg');
const AddCardForm = ({ onClick, setIsAddCard }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [cardTypeList, setCardTypeList] = useState([]);
    const [subSection, setSubSection] = useState([]);
    const [branding, setBranding] = useState([]);
    const [data, setData] = useState([]);
    const [accountHolderName, setAccountHolderName] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const cardType = useRef();
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    const [isShowPopup, setIsShowPopup] = useState(false);
    const {
        control,
        handleSubmit,
        watch,
        resetField,
        formState: { errors },
    } = useForm();
    const { jwtToken } = useAuth();
    cardType.current = watch('cardType');

    useEffect(() => {
        if (!cardType.current) return;
        setOpen(true);
        setLoading(true);
        resetField('brand');
        getBranding(jwtToken, cardType.current).then((response) => {
            const mappedData = response?.map((item) => {
                return {
                    text: item.brandName,
                    value: item.code,
                };
            });
            setBranding(mappedData);
            setLoading(false);
        });
    }, [cardType.current]);

    useEffect(() => {
        WalletService.getSubsection(jwtToken).then((response) => {
            const mappedData = response?.map((item) => {
                return {
                    text: item.label,
                    value: item.subsection,
                };
            });
            setSubSection(response);
            setCardTypeList(mappedData);
        });
    }, []);

    useEffect(() => {
        getAccountInfo(jwtToken).then((response) => {
            if (response) {
                setAccountHolderName(response.fullName);
            } else {
                setErrorMsg(t('error-message.common'));
                setIsShowPopup(true);
            }
        });
    }, []);

    const onSubmit = (data) => {
        setIsSubmit(true);
        const paymentMethod = subSection.find(
            (item) => item.subsection === data.cardType,
        )?.paymentMethod;
        const body = {
            paymentMethod: paymentMethod,
            cardHolderName: data.cardHolderName,
            brand: data.brand || '',
        };
        WalletService.createPaymentAccount(jwtToken, body).then((response) => {
            if (isSuccess(response?.code)) {
                setData(response.result)
            } else {
                setIsSubmit(false);
                setErrorMsg(response?.message || t('error-message.common'));
                setIsShowPopup(true);
            }
        });
    };

    return (
        <>
            {data.length === 0 ? (
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Popup
                        open={isShowPopup}
                        content={errorMsg}
                        onClose={() => {
                            setIsShowPopup(false);
                        }}
                        onAgree={() => {
                            setIsShowPopup(false);
                        }}
                    />
                    <Box sx={boxContainerStyle}>
                        <PageTitle
                            title={t('manage-accounts.add-card')}
                            onClick={isSubmit ? null : onClick}
                            submit={`${isSubmit}`}
                        />
                        <Box sx={boxItemStyle}>
                            <Typography sx={titleStyle(theme, isSubmit)}>
                                {t('manage-accounts.choose-card')}
                            </Typography>
                        </Box>
                        <Box sx={boxCardTypeStyle}>
                            <Typography sx={cardTypeStyle(theme, isSubmit)}>
                                {t('card-information.card-type')}
                            </Typography>
                            <Box sx={{ minWidth: '288px', maxWidth: '288px' }}>
                                <Controller
                                    name="cardType"
                                    control={control}
                                    render={({ field }) => (
                                        <Dropdown
                                            icon={iconCard}
                                            disabled={
                                                cardTypeList.length === 0 ||
                                                isSubmit
                                            }
                                            label={t(
                                                'transfer.tab.transfer-money.choose-a-card',
                                            )}
                                            options={cardTypeList}
                                            onChangeValue={(value) =>
                                                field.onChange(value)
                                            }
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                        {open && (
                            <Box sx={boxAccountInformation}>
                                <Box sx={boxItemStyle}>
                                    <Typography sx={titleStyle(theme, isSubmit)}>
                                        {t('add-funds.card-information.title')}
                                    </Typography>
                                </Box>
                                <>
                                    {loading ? (
                                        <Box sx={boxLoadingStyle}>
                                            <Loading />
                                        </Box>
                                    ) : (
                                        <>
                                            {branding.length > 0 && (
                                                <Box
                                                    sx={{
                                                        ...boxCardTypeStyle,
                                                        ...boxBrandingStyle(
                                                            theme,
                                                            errors.brand?.type ===
                                                                'required',
                                                        ),
                                                    }}
                                                >
                                                    <Typography
                                                        sx={cardTypeStyle(
                                                            theme,
                                                            isSubmit,
                                                        )}
                                                    >
                                                        {t(
                                                            'manage-accounts.branding',
                                                        )}
                                                    </Typography>
                                                    <Box sx={boxItemBrandingStyle}>
                                                        <Controller
                                                            name="brand"
                                                            control={control}
                                                            rules={{
                                                                required: true,
                                                            }}
                                                            render={({ field }) => (
                                                                <Dropdown
                                                                    label={t(
                                                                        'manage-accounts.choose-branding',
                                                                    )}
                                                                    disabled={
                                                                        isSubmit
                                                                    }
                                                                    options={
                                                                        branding
                                                                    }
                                                                    onChangeValue={(
                                                                        value,
                                                                    ) => {
                                                                        field.onChange(
                                                                            value,
                                                                        );
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                        {errors.brand?.type ===
                                                            'required' && (
                                                            <Typography
                                                                sx={errorStyle(
                                                                    theme,
                                                                )}
                                                            >
                                                                {t(
                                                                    'validation.required',
                                                                    {
                                                                        field: t(
                                                                            'manage-accounts.branding',
                                                                        ),
                                                                    },
                                                                )}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </Box>
                                            )}
                                            <Box
                                                sx={{
                                                    ...boxCardTypeStyle,
                                                    ...boxAccountHolderName(theme),
                                                }}
                                            >
                                                <Box sx={{ width: '50%' }}>
                                                    <Typography
                                                        sx={cardTypeStyle(
                                                            theme,
                                                            isSubmit,
                                                        )}
                                                    >
                                                        {t(
                                                            'manage-accounts.account-holder-name',
                                                        )}
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    sx={titleAccountHolderNameStyle(
                                                        theme,
                                                        isSubmit,
                                                    )}
                                                >
                                                    {accountHolderName}
                                                </Typography>
                                                <Controller
                                                    name="cardHolderName"
                                                    control={control}
                                                    defaultValue={accountHolderName}
                                                    render={({ field }) => (
                                                        <input
                                                            type="hidden"
                                                            {...field}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                            <Box sx={{ opacity: 1 }}>
                                                <Button
                                                    label={t(
                                                        'transfer.tab.transfer-money.submit',
                                                    )}
                                                    type="submit"
                                                    variant="contained"
                                                    disabled={isSubmit || loading}
                                                    loading={isSubmit}
                                                    endIcon={
                                                        !isSubmit && (
                                                            <img
                                                                style={
                                                                    arrowIconStyle
                                                                }
                                                                src={arrowRight}
                                                            />
                                                        )
                                                    }
                                                ></Button>
                                            </Box>
                                        </>
                                    )}
                                </>
                            </Box>
                        )}
                    </Box>
                </form>
            ): (
                <AddCardSuccessful response={data} setIsAddCard={setIsAddCard} />
            )}
        </>
    );
};

export default AddCardForm;
