/**
 * @description Define style attribute for Button component.
 */

import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

export const boxStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
};

export const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    '&.MuiCircularProgress-root': {
        width: '24px !important',
        height: '24px !important',
        color: theme.appPalette?.grey.grey20,
    },
}));

export const buttonStyle = {
    fontStyle: 'normal',
    fontSize: '16px',
    textTransform: 'none',
    fontWeight: 600,
    borderRadius: '12px',
    boxShadow: 'none',
};
