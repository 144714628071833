/**
 * @description Define style attribute for CardInformationForm component.
 */

export const containerStyle = (loading) => ({
    paddingTop: '100px',
    width: '648px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    paddingBottom: '60px',
    opacity: loading ? 0.5 : 1
});

export const descStyle = (theme) => ({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineheight: '20px',
    color: theme.appPalette?.grey.grey60,
    marginLeft: '44px',
});

export const boxItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
};

export const boxItemFormStyle = (theme) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '40px',
    paddingTop: '40px',
    borderBottom: `1px solid ${theme.appPalette?.grey.grey8}`,
});

export const labelStyle = (theme) => ({
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px', 
    color: theme.appPalette?.grey.grey80,
});

export const hyperTextStyle = (theme) => ({
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.appPalette?.grey.grey60,
})

export const inputStyle = {
    width: '280px',
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
}

export const boxCardNumberStyle = (theme) => ({
    paddingTop: '40px',
    paddingBottom: '40px',
    borderBottom: `1px solid ${theme.appPalette?.grey.grey8}`,
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
});

export const boxItemCardNumberStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
}

export const errorStyle = {
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    color: '#DD1D1D'
}

export const requiredStyle = {
    color: '#DD1D1D',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
}