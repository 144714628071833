/**
 * @description Define the expected data types for props passed to PaymentMethodItem component.
 */

import { PropTypes } from 'prop-types';

export const paymentMethodItemPropTypes = {
    value: PropTypes.string.isRequired,
    prefixIcon: PropTypes.string.isRequired,
    suffixIcon: PropTypes.string,
    onClick: PropTypes.func,
};
