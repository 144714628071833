/**
 * @description ToastContext component.
 */

import ToastMessage from 'components/toast-message/ToastMessage';
import React, { createContext, useState, useContext } from 'react';

const ToastContext = createContext();

export const useToast = () => useContext(ToastContext);

export const ToastProvider = ({ children }) => {
    const [toastState, setToastState] = useState({
        open: false,
        isSuccess: false,
        isError: false,
        isWarning: false,
        isInformation: false,
        title: '',
        content: '',
        maxWidth: '',
    });

    const showToast = (
        isSuccess,
        isError,
        isWarning,
        isInformation,
        title,
        content,
        maxWidth,
    ) => {
        setToastState({
            open: true,
            isSuccess: isSuccess,
            isError: isError,
            isWarning: isWarning,
            isInformation: isInformation,
            title,
            content,
            maxWidth,
        });
    };

    const hideToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setToastState({
            ...toastState,
            open: false,
        });
    };

    return (
        <ToastContext.Provider value={{ showToast, hideToast, toastState }}>
            <ToastMessage
                open={toastState.open}
                handleClose={hideToast}
                title={toastState.title}
                content={toastState.content}
                maxWidth={toastState.maxWidth}
                isSuccess={toastState.isSuccess}
                isError={toastState.isError}
                isWarning={toastState.isWarning}
                isInformation={toastState.isInformation}
            />
            {children}
        </ToastContext.Provider>
    );
};
