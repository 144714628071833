/**
 * @description Transfer component.
 */

import Tab from 'components/tab/Tab';
import React from 'react';
import { Container, Grid } from '@mui/material';
import {
    containerStyle,
    tabGroupStyle,
    tabPanelStyle
} from 'scenes/transfer/Transfer.style';
import { useTranslation } from 'react-i18next';
import TabPanel from "../../components/tab/tab-panel/TabPanel";
import TransferForm from 'components/transfer-form/TransferForm';

const Transfer = () => {
    const { t } = useTranslation();
    const [value, setValue] = React.useState(0);
    const handleChange = (newValue) => {
        setValue(newValue);
    };
    const tabs = [
        t('transfer.tab.transfer-money.title'),
    ];
    return (
        <Container sx={containerStyle}>
            <Grid sx={tabGroupStyle}>
                <Tab tabs={tabs} onTabChange={handleChange}/>
            </Grid>
            <Grid sx={tabPanelStyle}>
                <TabPanel value={value} index={0}>
                    <TransferForm />
                </TabPanel>
            </Grid>
        </Container>
    );
};
export default Transfer;
