/**
 * @description Define the expected data types for props passed to ActionItem component.
 */

import PropTypes from "prop-types";

export const actionItemPropTypes = {
    imagePath: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    action: PropTypes.func
};