/**
 * @description localStorage.js file.
 */

const LOCAL_STORAGE = {
    SEARCH: 'search',
    JWT_TOKEN: 'jwtToken',
    OTP_EXPIRE_DATE: 'otpExpireDate',
    OTP_NONCE: 'otpNonce',
};
export default LOCAL_STORAGE;
