/**
 * @description accountType.js file.
 */

const ACCOUNT_TYPE = {
    REWARD_ACCOUNT: '1000',
    BANK: '1001',
    SAVING: '1002'
};
export default ACCOUNT_TYPE;
