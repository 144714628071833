/**
 * @description Define the expected data types for props passed to TransactionList component.
 */

import { PropTypes } from 'prop-types';

export const transactionListPropTypes = {
    pageSize: PropTypes.number,
    term: PropTypes.string,
    isShowPagination: PropTypes.bool,
    isShowHeader: PropTypes.bool,
    isShowBtnViewMore: PropTypes.bool,
};
