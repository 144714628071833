import React, { useState, useEffect } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import resourceUrl from 'resourceResolver';
import { boxLoadingStyle, boxNameStyle } from './AccountsListing.style';
import { useTranslation } from 'react-i18next';
import Table from 'components/table/Table';
import Loading from 'components/loading/Loading';
import NoDataFound from 'components/no-data-found/NoDataFound';
import WalletService from 'services/wallet/walletService';
import { useAuth } from 'hooks/useAuth';
import PAYMENT_METHOD from 'constants/paymentMethod';
import SUBSECTION from 'constants/subsection';

const iconImages = {
    SAND_DOLLAR: resourceUrl('icons/sand-dollar.svg'),
    SAVING: resourceUrl('icons/SA-icon.svg'),
    1001: resourceUrl('icons/kemp.svg'),
    1002: resourceUrl('icons/georgia-unemployment.svg'),
    1000: resourceUrl('icons/EBT.svg'),
};

const AccountsListing = () => {
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const theme = useTheme();
    const { t } = useTranslation();
    const { jwtToken } = useAuth();
    iconImages.KK = theme.cashCardIcon;

    const columns = [
        {
            id: 'cardName',
            label: t('sidebar.cards'),
            minWidth: 322.67,
        },
        {
            id: 'last4Digits',
            label: t('add-funds.card-information.card-number'),
            minWidth: 322.67,
        },
        {
            id: 'balance',
            label: t('manage-accounts.balance'),
            minWidth: 322.67,
        },
    ];

    useEffect(() => {
        setLoading(true);
        WalletService.getAccountListing(jwtToken, `${PAYMENT_METHOD.CASH_CARD},${PAYMENT_METHOD.SAND_DOLLAR}`).then(
            (response) => {
                const data = response.map((item) => {
                    return {
                        ...item,
                        last4Digits: (
                            <Box>
                                <Typography>••{item.last4Digits}</Typography>
                            </Box>
                        ),
                        cardName: (
                            <Box sx={boxNameStyle}>
                                {item.ssCategory === SUBSECTION.KASH ? (
                                    <>
                                        <img
                                            src={iconImages[item.branchCode] || theme.cashCardIcon}
                                            alt={item.cardName}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <img
                                            src={iconImages[item.ssCategory]}
                                            alt={item.cardName}
                                        />
                                    </>
                                )}
                                <Typography>{item.cardName}</Typography>
                            </Box>
                        ),
                    };
                });
                setData(data);
                setLoading(false);
            },
        );
    }, []);

    return (
        <>
            {loading && (
                <Box sx={boxLoadingStyle}>
                    <Loading />
                </Box>
            )}

            {!loading && data.length === 0 && (
                <NoDataFound
                    icon={theme.noAccountsFound}
                    label={t('card-listing.no-card-found')}
                />
            )}

            {!loading && data.length > 0 && (
                <Table columns={columns} rows={data} />
            )}
        </>
    );
};

export default AccountsListing;
