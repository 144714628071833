/**
 * @description Define style attribute for Input component.
 */

import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

export const StyledTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        display: 'flex',
        padding: '8px 24px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '8px',
        background: '#FFF',
        boxShadow:
            '0px 12px 96px 0px rgba(228, 229, 252, 0.12), 0px 0px 10px 0px rgba(228, 229, 252, 0.50)',
        height: '50px',
        color: theme.appPalette?.grey.grey80,
        fontSize: theme.typography.body1.fontSize,
        fontStyle: 'normal',
        fontWeight: theme.typography.body1.fontWeight,
        lineHeight: '18px',
        letterSpacing: '0.3px',
    },
    '& .MuiInputBase-input': {
        '::placeholder': {
            color: theme.appPalette?.grey.grey60,
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiOutlinedInput-input': {
        color: theme.typography.body1.color,
        fontSize: theme.typography.body1.fontSize,
        fontStyle: 'normal',
        fontWeight: theme.typography.body1.fontWeight,
        lineHeight: '18px',
        letterSpacing: '0.3px',
        padding: 0,
    },
    '& .MuiInputBase-root.Mui-focused': {
        boxShadow: '0px 0px 16px 0px rgba(178, 164, 202, 0.50) !important',
    },
    '& .Mui-disabled': {
        background: theme.appPalette?.grey.grey8,
    },
    '& .MuiSvgIcon-root': {
        color: theme.palette.primary.main,
    },
}));

export const cancelIconStyle = (theme) => ({
    width: '16px',
    cursor: 'pointer',
    color: `${theme.appPalette?.grey.grey80} !important`,
});
