export const boxContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
};

export const boxWrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '24px',
    width: '600px',
    alignItems: 'center',
};

export const imageStyle = {
    width: '64px',
    height: '64px',
};

export const successWrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    justifyContent: 'center',
    alignItems: 'center'
};

export const successfulTitleStyle = (theme) => ({
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '34px',
    color: theme.appPalette?.grey.grey80,
});

export const successfulDescriptionStyle = (theme) => ({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.appPalette?.grey.grey60,
});

export const accountAddressStyle = {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
};

export const boxItemStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
};