/**
 * @description Define APIs related to Accounting services.
 */

import Api from 'configs/api';
import { isSuccess } from 'constants/statusCode';
import ApiClient from 'services/api-client/apiClient';
import { createSearchParams } from 'react-router-dom';

export async function getLinkedAccounts(jwtToken) {
    try {
        const response = await ApiClient.get(Api.GET_LINKED_ACCOUNTS, {
            headers: {
                Authorization: 'Bearer ' + jwtToken,
            },
        });

        if (response && isSuccess(response.code)) {
            const mappedData = response.result.map((item) => {
                return {
                    id: item.accountId,
                    cardHolder: item.cardHolderName,
                    cardName: item.cardName,
                    cardType: item.cardType,
                    cardNum: item.cardNumber,
                    cardExpiry: item.expiryDate,
                    cardEmail: item.cardEmail,
                    cardToken: item.cardToken,
                    paymentMethod: item.paymentMethod,
                };
            });

            response.result = mappedData;

            return response.result;
        }

        return undefined;
    } catch (e) {
        return undefined;
    }
}

export async function getAccountBalance(jwtToken, accountType) {
    try {
        const response = await ApiClient.get(
            `${Api.GET_ACCOUNT_BALANCE}?${createSearchParams({ accountType: accountType })}`,
            {
                headers: {
                    Authorization: 'Bearer ' + jwtToken,
                },
            },
        );

        if (response && isSuccess(response.code)) {
            return response.result;
        }

        return undefined;
    } catch (e) {
        return undefined;
    }
}

export async function getCashFlow(jwtToken, fromDate, toDate) {
    const fallBackResponse = [
        {
            type: 'CashIn',
            totalAmount: 0,
            formattedTotalAmount: '$0.00',
            summaries: [],
        },
        {
            type: 'CashOut',
            totalAmount: 0,
            formattedTotalAmount: '$0.00',
            summaries: [],
        },
    ];

    try {
        const response = await ApiClient.get(Api.GET_CASH_FLOW, {
            params: {
                fromDate,
                toDate,
            },
            headers: {
                Authorization: 'Bearer ' + jwtToken,
            },
        });

        if (
            response &&
            isSuccess(response.code) &&
            response.result &&
            response.result.length > 0
        ) {
            const data = response.result;
            // Calculate valueProgress for each summary
            const newData = data.map((item) => ({
                ...item,
                summaries: item.summaries.map((summary) => ({
                    ...summary,
                    valueProgress: (summary.amount / item.totalAmount) * 100,
                })),
            }));
            return newData;
        }

        return fallBackResponse;
    } catch (e) {
        return fallBackResponse;
    }
}

export async function getAccountSummary(jwtToken, accountType) {
    try {
        const requestData = {
            accountType: accountType,
        };
        const response = await ApiClient.get(Api.GET_ACCOUNT_SUMMARY, {
            params: requestData,
            headers: {
                Authorization: 'Bearer ' + jwtToken,
            },
        });

        if (response && isSuccess(response.code)) {
            return response.result;
        }
        return undefined;
    } catch (e) {
        return undefined;
    }
}

export async function getCardListing(jwtToken, paymentMethods, hideBalance = false) {
    try {
        const response = await ApiClient.get(Api.GET_CARD_LISTING, {
            params: {
                paymentMethods: paymentMethods,
                hideBalance: hideBalance,
            },
            headers: {
                Authorization: 'Bearer ' + jwtToken,
            },
        });

        if (response && isSuccess(response.code)) {
            const mappedData = response.result.map((item) => {
                return {
                    id: item.ssId,
                    balance: item.balance,
                    cardHolder: item.cardHolderName,
                    cardNum: item.last4Digits,
                    cardName: item.cardName,
                    ssCategory: item.ssCategory,
                    paymentMethod: item.paymentMethod,
                    branchCode: item.branchCode,
                    brandName: item.brandName,
                };
            });

            response.result = mappedData;
            return response.result;
        }

        return [];
    } catch (e) {
        return [];
    }
}

