/**
 * @description Define style attribute for PageTitle component.
 */

import { styled } from '@mui/system';
import { ReactSVG } from 'react-svg';

export const boxContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
};

export const titleStyle = (theme, submit) => ({
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '34px',
    color:
        submit === 'true'
            ? theme.appPalette?.grey.grey20
            : theme.appPalette?.grey.grey80,
});

export const CustomSVG = styled(ReactSVG)(({ theme, submit }) => ({
    svg: {
        'path[fill]': {
            fill:
                submit === 'true'
                    ? theme.appPalette?.grey.grey20
                    : theme.appPalette?.grey.grey80,
        },
        'path[stroke]': {
            stroke:
                submit === 'true'
                    ? theme.appPalette?.grey.grey20
                    : theme.appPalette?.grey.grey80,
        },
        width: '32px',
        height: '32px',
    },
    lineHeight: 0,
    cursor: submit === 'true' ? 'default' : 'pointer',
}));

