/**
 * @description Define style attribute for SidebarItem component.
 */

import { menuClasses } from 'react-pro-sidebar';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';

export const baseButtonStyles = {
    padding: '0 !important',
};

export const hoverButtonStyles = (theme) => ({
    backgroundColor: `${theme.appPalette.primary.primary8} !important`,
    borderRadius: '10px',
    [`.${menuClasses.icon}`]: {
        animation: 'swing ease-in-out 0.5s 1 alternate',
    },
});

export const hoverSelectedButtonStyles = {
    backgroundColor: 'transparent !important',
    cursor: 'default',
    [`.${menuClasses.icon}`]: {
        animation: 'none',
    },
};

export const iconStyles = {
    width: '40px',
    height: '40px',
    minWidth: '40px',
    marginRight: '8px',
};

export const activeIconStyles = (theme) => ({
    [`.${menuClasses.icon}.ps-active > div div svg path[fill="#55585E"]`]: {
        fill: theme.appPalette?.primary.primary100,
    },
    [`.${menuClasses.icon}.ps-active > div div svg path[stroke="#55585E"]`]: {
        stroke: theme.appPalette?.primary.primary100,
    },
    [`.${menuClasses.icon} > div div svg path[fill="#55585E"]`]: {
        fill: theme.appPalette.grey.grey80,
    },
    [`.${menuClasses.icon} > div div svg path[stroke="#55585E"]`]: {
        stroke: theme.appPalette.grey.grey80,
    },
});

export const itemStyle = (theme) => ({
    [`.${menuClasses.button}`]: baseButtonStyles,
    [`.${menuClasses.button}:hover`]: hoverButtonStyles(theme),
    [`.${menuClasses.button}.ps-active:hover`]: hoverSelectedButtonStyles,
    [`.${menuClasses.icon}`]: iconStyles,
    [`.${menuClasses.button}:focus`]: { outline: 'none' },
    ...activeIconStyles(theme),
});
export const StyledTypography = styled(Typography)(({ theme, selected }) => ({
    '&.MuiTypography-root': {
        fontWeight: '600',
        fontSize: '14px',
        fontStyle: 'normal',
        lineHeight: '18px',
        color: selected
            ? theme.appPalette?.primary.primary100
            : theme.appPalette.grey.grey80,
    },
}));
