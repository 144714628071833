/**
 * @description Define the expected data types for props passed to PointNetworkItem component.
 */

import { PropTypes } from 'prop-types';

export const pointNetworkItemPropTypes = {
    title: PropTypes.string,
    content: PropTypes.string,
    caption: PropTypes.string,
    imagePath: PropTypes.string,
};
