/**
 * @description Define style attribute for TransferForm component.
 */

import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { ReactSVG } from 'react-svg';

export const arrowIconStyle = {
    width: '24px',
    height: '24px',
};

export const containerStyle = {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    padding: '0px !important',
    width: '55%',
};

export const contentGridStyle = (theme, isSubmit) => ({
    justifyContent: 'space-between',
    display: 'flex',
    minHeight: '74px',
    alignItems: 'center',
    padding: '20px 0 20px 0',
    borderBottom: `1px solid ${theme.appPalette?.grey.grey12}`,
    opacity: isSubmit ? 0.5 : 1,
});

export const StyledH4 = styled(Typography)(({ theme }) => ({
    color: theme.appPalette?.grey.grey80,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
}));

export const CustomSVG = styled(ReactSVG)(({ theme }) => ({
    svg: {
        'path[fill]': {
            fill: theme.appPalette?.grey.grey80,
        },
        'path[stroke]': {
            stroke: theme.appPalette?.grey.grey80,
        },
        width: '32px',
        height: '32px',
    },
    lineHeight: 0,
}));

export const titleStyle = {
    padding: '48px 0 48px 0',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '12px',
};

export const iconBackStyle = {
    cursor: 'pointer',
    width: '32px',
    height: '32px',
};

export const inputDescriptionStyle = {
    marginTop: '5px',
    color: '#71718E',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
};

export const inlineErrorStyle = (theme) => ({
    color: '#FF4646',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    marginTop: '4px',
});

export const styledH4LineHeight36 = {
    lineHeight: '36px',
};

export const transferToStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    marginTop: '24px',
};

export const transferBoxStyle = (theme) => ({
    display: 'flex',
    padding: '20px 0px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
    borderBottom: `1px solid ${theme.appPalette?.grey.grey12}`,
    width: '100%',
});

export const transferBoxItemStyle = (isSubmit) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    opacity: isSubmit ? 0.5 : 1,
});

export const dropdownTransferStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
}
