/**
 * @description TravelNetworkItem component.
 */

import { Grid, useTheme } from '@mui/material';
import React from 'react';
import OverflowTip from '../tooltip/OverflowTip';
import {
    componentStyle,
    titleStyle,
    contentStyle,
    imageContainerStyle,
    imageStyle,
    tooltipStyle,
} from './TravelNetworkItem.style';
import { travelNetworkItemPropTypes } from 'types/travelNetworkItemPropTypes';
const TravelNetworkItem = ({ title, content, imagePath, sx }) => {
    const theme = useTheme();
    return (
        <Grid container item sx={componentStyle(theme)}>
            <Grid container item xs={8.5}>
                <OverflowTip
                    title={title}
                    textStyle={titleStyle(theme)}
                ></OverflowTip>
                <OverflowTip
                    title={content}
                    tooltipStyle={tooltipStyle(theme)}
                    textStyle={contentStyle(theme)}
                ></OverflowTip>
            </Grid>
            <Grid container item xs={3.5} sx={imageContainerStyle}>
                <img src={imagePath} alt="imageItem" style={imageStyle} />
            </Grid>
        </Grid>
    );
};

TravelNetworkItem.propTypes = {
    ...travelNetworkItemPropTypes,
};

export default TravelNetworkItem;

