/**
 * @description SearchBox component.
 */

import { InputAdornment, Link } from '@mui/material';
import { useState } from 'react';
import resourceUrl from '../../resourceResolver';
import { StyledTextField, searchBoxStyle, CustomSVG } from './SearchBox.style';
import { searchBoxPropTypes } from 'types/searchBoxPropTypes';

const searchIcon = resourceUrl('icons/search-icon.svg');
const filterIcon = resourceUrl('icons/tune-icon.svg');
const deleteIcon = resourceUrl('icons/remove-icon.svg');

const SearchBox = ({
    placeholder,
    filterAction,
    searchAction,
    defaultValue,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [searchText, setSearchText] = useState(defaultValue);

    const handleClear = () => {
        setSearchText('');
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsFocused(false);
        }, 200);
    };

    return (
        <StyledTextField
            fullWidth={true}
            onFocus={() => setIsFocused(true)}
            onBlur={handleBlur}
            onChange={(event) => setSearchText(event.target.value)}
            onKeyUp={(event) => {
                if (event.key === 'Enter' && searchAction) {
                    searchAction(searchText);
                }
            }}
            InputProps={{
                startAdornment: SearchBox.StartAdornment,
                endAdornment: (
                    <InputAdornment position="end" data-testid="action-icon">
                        <Link
                            style={{
                                display: 'flex',
                                cursor: 'pointer',
                            }}
                        >
                            {searchText && isFocused && (
                                <CustomSVG
                                    onClick={handleClear}
                                    src={deleteIcon}
                                />
                            )}
                        </Link>
                    </InputAdornment>
                ),
                placeholder: placeholder,
                sx: { ...searchBoxStyle },
            }}
            value={searchText}
            variant="outlined"
        ></StyledTextField>
    );
};

SearchBox.propTypes = {
    ...searchBoxPropTypes,
};

SearchBox.StartAdornment = (
    <InputAdornment position="start" data-testid="search-icon">
        <CustomSVG src={searchIcon} issearchicon={'true'} />
    </InputAdornment>
);

export default SearchBox;

