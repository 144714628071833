/**
 * @description ActionGroup component.
 */

import { Box, useTheme } from '@mui/material';
import ActionItem from 'components/action-item/ActionItem';
import { boxContentStyle } from './ActionGroup.style';
import { actionGroupPropTypes } from 'types/actionGroupPropTypes';
const ActionGroup = ({ actionItems, width = '100%' }) => {
    const theme = useTheme();
    const isCenter = actionItems.length === 1;
    const isSpaceEvenly = actionItems.length === 2;

    return (
        <Box sx={boxContentStyle(theme, width, isCenter, isSpaceEvenly)}>
            {actionItems.map((item) => (
                <ActionItem
                    key={item.text}
                    imagePath={item.imagePath}
                    text={item.text}
                    action={item.action}
                ></ActionItem>
            ))}
        </Box>
    );
};

ActionGroup.propTypes = {
    ...actionGroupPropTypes,
};

export default ActionGroup;

