import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material/styles';

export const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
    paddingBottom: '60px',
    width: '1016px',
};

export const boxContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    width: '100%',
};

export const CustomSVG = styled(ReactSVG)(({ theme }) => ({
    svg: {
        'path[fill="#1E7CD7"]': {
            fill: theme.appPalette?.primary.primary100,
        },
        'path[stroke="#1E7CD7"]': {
            stroke: theme.appPalette?.primary.primary100,
        },
    },
    lineHeight: 0,
}));

export const boxStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
};

export const titleStyle = {
    fontSize: '28px',
    fontWeight: '600',
    lineHeight: '34px',
};
