export const boxContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
    width: '600px',
};

export const boxItemStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
};

export const titleStyle = (theme, isSubmit) => ({
    fontWeight: 600,
    fontSize: '24px',
    lineHeight: '28px',
    color: isSubmit
        ? theme.appPalette?.grey.grey20
        : theme.appPalette?.grey.grey80,
});

export const descStyle = {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '20px',
};

export const boxCardTypeStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
};

export const arrowIconStyle = {
    width: '24px',
    height: '24px',
};

export const boxAccountInformation = {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
};

export const titleAccountHolderNameStyle = (theme, isSubmit) => ({
    color: isSubmit
        ? theme.appPalette?.grey.grey20
        : theme.appPalette?.grey.grey80,
});

export const boxBrandingStyle = (theme, required) => ({
    height: required ? '110px' : '90px',
    borderBottom: `1px solid ${theme.appPalette?.grey.grey12}`,
});

export const boxAccountHolderName = (theme) => ({
    height: '58px',
    borderBottom: `1px solid ${theme.appPalette?.grey.grey12}`,
    display: 'flex',
    justifyContent: 'space-between',
});

export const cardTypeStyle = (theme, isSubmit) => ({
    width: '50%',
    color: isSubmit
        ? theme.appPalette?.grey.grey20
        : theme.appPalette?.grey.grey80,
});

export const errorStyle = (theme) => ({
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    color: theme.appPalette?.danger,
});

export const boxItemBrandingStyle = {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
};

export const boxLoadingStyle = {
    display: 'flex',
    justifyContent: 'center',
    height: '204px',
    alignItems: 'center',
};

