/**
 * @description Define APIs related to Profile services.
 */

import ApiClient from 'services/api-client/apiClient';
import Api from 'configs/api';
import { isSuccess } from 'constants/statusCode';
import { createSearchParams } from 'react-router-dom';

export async function getNearbyMerchantCount(jwtToken, coordinate) {
    try {
        const response = await ApiClient.get(
            `${Api.NEARBY_MERCHANT_COUNT}?${createSearchParams(coordinate)}`,
            {
                headers: {
                    Authorization: 'Bearer ' + jwtToken,
                },
            },
        );

        if (response && isSuccess(response.code)) {
            return response.result;
        }

        return undefined;
    } catch (e) {
        return undefined;
    }
}

export async function getLanguages() {
    const fallbackResponse = [
        {
            language: 'English',
            code: 'en',
            isBase: true,
        },
    ];
    try {
        const response = await ApiClient.get(Api.GET_LANGUAGES);
        if (response && isSuccess(response.code)) {
            return response.result;
        }
        return fallbackResponse;
    } catch (e) {
        return fallbackResponse;
    }
}

export async function getAccountInfo(jwtToken) {
    try {
        const response = await ApiClient.get(Api.GET_ACCOUNT_INFO, {
            headers: {
                Authorization: 'Bearer ' + jwtToken,
            },
        });
        if (response && isSuccess(response.code)) {
            return response.result;
        }
        return null;
    } catch (e) {
        return null;
    }
}

