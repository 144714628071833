/**
 * @description Define APIs related to Transaction services.
 */

import Api from "configs/api";
import { isSuccess } from 'constants/statusCode';
import { format } from 'date-fns';
import ApiClient from "../api-client/apiClient";

const TransactionService = {
    searchTransactions: async (pageIndex, pageSize, term, jwtToken) => {
        try {
            const response = await ApiClient.get(Api.SEARCH_TRANSACTIONS, {
                params: {
                    pageIndex: pageIndex,
                    pageSize: pageSize,
                    term: term,
                },
                headers: {
                    Authorization: 'Bearer ' + jwtToken,
                },
            });
            if (response && isSuccess(response.code)) {
                response.result.data = response.result.data?.map((item) => {
                    return {
                        id: item.transactionId,
                        dateStatus: TransactionService.formatDate(
                            item.transactionDate,
                        ),
                        status: item.status,
                        actionType: item.transactionType,
                        categoryType: item.businessCategory,
                        amount: item.amount, // Business rule needed
                        balance: '-',
                    };
                });

                return response.result;
        } else {
            return {
                totalPages: 0,
                pageSize: pageSize,
                totalElements: 0,
            };
        }
        } catch (e) {
            return {
                totalPages: 0,
                pageSize: pageSize,
                totalElements: 0,
            };
        }
    },

    formatDate: (date) => {
        try {
            date = new Date(date);
            return format(date, 'MM/dd/yyyy');
            // If an invalid date given then return blank
        } catch (e) {
            return '';
        }
    },
};

export default TransactionService;
