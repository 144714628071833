/**
 * @description OtpConfirmationForm component.
 */

import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, { useEffect, useState } from 'react';
import resourceUrl from 'resourceResolver';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import {
    CustomSVG,
    logoContainerStyle,
    logoStyle,
    titleStyle,
    iconBackStyle,
    otpContainerStyle,
    errorContainerStyle,
    countDownCaptionStyle,
    inputStyle,
    focusStyle,
} from './OtpConfirmationForm.style';
import OtpInput from 'react-otp-input';
const arrowLeft = resourceUrl('icons/arrow-left.svg');

// Define inputType as 'tel' for number input
const inputType = 'tel';

const CustomInput = ({ props }) => {
    const theme = useTheme();
    const [isFocus, setIsFocus] = useState(false);
    const handleFocus = (event) => {
        if (props.onFocus) {
            props.onFocus(event);
        }
        setIsFocus(true);
    };
    const handleBlur = (event) => {
        if (props.onBlur) {
            props.onBlur(event);
        }
        setIsFocus(false);
    };
    return (
        <input
            {...props}
            onFocus={handleFocus}
            onBlur={handleBlur}
            style={{
                ...inputStyle(theme),
                ...(isFocus ? focusStyle(theme) : {}),
            }}
        />
    );
};

const OtpConfirmationForm = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const [otp, setOtp] = React.useState('');
    const [otpExpireTime, setOtpExpireTime] = React.useState(0);
    const [error, setError] = React.useState('');
    const { otpExpireDate, logout, verify2FACode } = useAuth();
    const numInputs = 4;

    useEffect(() => {
        const currentTimestamp = Date.now();
        const difference = Math.floor(
            (otpExpireDate - currentTimestamp) / 1000,
        );

        if (difference > 0) {
            setOtpExpireTime(difference);
        } else {
            setOtpExpireTime(0);
        }
        const intervalId = setInterval(() => {
            setOtpExpireTime((prevSeconds) =>
                prevSeconds > 0 ? prevSeconds - 1 : 0,
            );
        }, 1000);

        return () => clearInterval(intervalId);
    }, [otpExpireDate]);

    const handleOtpChange = (newValue) => {
        if (newValue.length === numInputs) {
            handleCompleteOtp(newValue);
        }
        setOtp(newValue);
        setError('');
    };
    const handleCompleteOtp = async (value) => {
        try {
            await verify2FACode(value);
        } catch (err) {
            setError(err.message);
        }
    };
    const goBack = () => {
        logout();
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            gap="24px"
            component="form"
            noValidate
        >
            <Grid container style={logoContainerStyle}>
                <img src={theme?.logoImage} alt="Logo" style={logoStyle} />
            </Grid>
            <Grid container sx={titleStyle}>
                <CustomSVG
                    src={arrowLeft}
                    onClick={goBack}
                    style={iconBackStyle}
                />
                <Typography
                    color={theme.appPalette?.grey.grey80}
                    variant="H1"
                    fontSize="34px"
                    fontWeight="600"
                    lineHeight="40px"
                >
                    {t('otp-confirmation-form.title')}
                </Typography>
            </Grid>
            <Grid container>
                <Typography fontSize="16px" fontWeight="400" lineHeight="20px">
                    {t('otp-confirmation-form.description')}
                </Typography>
            </Grid>
            <OtpInput
                value={otp}
                onChange={handleOtpChange}
                numInputs={numInputs}
                inputType={inputType}
                shouldAutoFocus={true}
                containerStyle={otpContainerStyle}
                renderInput={(props) => {
                    return <CustomInput props={props} />
                }}
            />
            {error && (
                <Grid container sx={errorContainerStyle}>
                    <Typography
                        color="#FF4646"
                        fontSize="14px"
                        fontWeight="400"
                        lineHeight="18px"
                    >
                        {error}
                    </Typography>
                </Grid>
            )}
            <Grid container sx={countDownCaptionStyle}>
                <Typography
                    color={theme.appPalette?.grey.grey60}
                    fontSize="16px"
                    fontWeight="400"
                    lineHeight="20px"
                >
                    {t('otp-confirmation-form.expire-in')} {otpExpireTime}s
                </Typography>
            </Grid>
        </Box>
    );
};

export default OtpConfirmationForm;

