/**
 * @description CardSlider component.
 */

import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Box, Typography, useTheme } from '@mui/material';
import CardImage from 'components/card-image/CardImage';
import PropTypes from 'prop-types';
import resourceUrl from 'resourceResolver';
import { getLinkedAccounts } from 'services/accounting/accountingService';
import { useAuth } from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';
import {
    btnStyles,
    btnArrowLeftStyle,
    btnArrowRightStyle,
    carouselItemStyle,
    imgStyle,
    containerStyle,
    boxContainerStyle,
    boxNoAccountsFoundStyle,
    titleStyle,
    noAccountsFoundStyle,
} from './CardSlider.style';
const arrowForwardIcon = resourceUrl('icons/arrow-forward.svg');
const arrowBackIcon = resourceUrl('icons/arrow-back.svg');
const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 576 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1,
    },
};
const customArrowPropType = {
    onClick: PropTypes.func,
};
const CustomArrowBack = ({ onClick }) => (
    <button onClick={onClick} style={{ ...btnStyles, ...btnArrowLeftStyle }}>
        <img src={arrowBackIcon} alt="arrow-back" />
    </button>
);
CustomArrowBack.propTypes = {
    ...customArrowPropType,
};

const CustomArrowForward = ({ onClick }) => (
    <button onClick={onClick} style={{ ...btnStyles, ...btnArrowRightStyle }}>
        <img src={arrowForwardIcon} alt="arrow-forward" />
    </button>
);

CustomArrowForward.propTypes = {
    ...customArrowPropType,
};

const externalLinkedAccountPropType = {
    data: PropTypes.array,
};

const CardSlider = () => {
    const [data, setData] = useState([]);
    const [fetched, setFetched] = useState(false);
    const theme = useTheme();
    const { jwtToken } = useAuth();
    const { t } = useTranslation();

    useEffect(() => {
        getLinkedAccounts(jwtToken).then((response) => {
            setFetched(true);
            setData(response);
        });
    }, []);

    return (
        <Box sx={boxContainerStyle(theme)}>
            <Typography sx={titleStyle(theme)}>
                {t('card-information.external-linked-accounts')}
            </Typography>
            {(!data || data.length === 0) && fetched ? (
                <Box sx={boxNoAccountsFoundStyle(theme)}>
                    <img
                        style={imgStyle}
                        src={theme.noAccountsFound}
                        alt={t('card-information.no-accounts-found')}
                    />
                    <Typography sx={noAccountsFoundStyle(theme)}>
                        {t('card-information.no-accounts-found')}
                    </Typography>
                </Box>
            ) : (
                <Box sx={containerStyle(theme)}>
                    <Carousel
                        axis="horizontal"
                        deviceType="desktop"
                        ssr={true}
                        containerClass={
                            data?.length < 3 ? 'carousel-container' : ''
                        }
                        responsive={responsive}
                        customLeftArrow={<CustomArrowBack />}
                        customRightArrow={<CustomArrowForward />}
                    >
                        {data?.map((item) => (
                            <div style={carouselItemStyle} key={item.id}>
                                <CardImage
                                    cardType={item.cardType}
                                    cardNum={item.cardNum}
                                    cardName={item.cardName}
                                    cardEmail={item.cardEmail}
                                    cardHolder={item.cardHolder}
                                    cardExpiry={item.cardExpiry}
                                />
                            </div>
                        ))}
                    </Carousel>
                </Box>
            )}
        </Box>
    );
};

CardSlider.propTypes = {
    ...externalLinkedAccountPropType,
};

export default CardSlider;

