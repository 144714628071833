/**
 * @description Define style attribute for Password component.
 */

import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ReactSVG } from 'react-svg';

export const StyledPassword = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-root': {
        display: 'flex',
        padding: '8px 24px',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        borderRadius: '8px',
        background:
            'linear-gradient(0deg, rgba(239, 239, 253, 0.10) 0%, rgba(239, 239, 253, 0.20) 100%), #FFF',
        boxShadow:
            '0px 12px 96px 0px rgba(228, 229, 252, 0.12), 0px 0px 10px 0px rgba(228, 229, 252, 0.50)',
        height: '50px',
        color: theme.appPalette?.grey.grey80,
        fontSize: theme.typography.body1.fontSize,
        fontStyle: 'normal',
        fontWeight: theme.typography.body1.fontWeight,
        lineHeight: '18px',
        letterSpacing: '0.3px',
    },
    '& .MuiInputBase-input': {
        '::placeholder': {
            color: theme.appPalette?.grey.grey60,
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiOutlinedInput-input': {
        color: theme.appPalette?.grey.grey80,
        fontSize: theme.typography.body1.fontSize,
        fontStyle: 'normal',
        fontWeight: theme.typography.body1.fontWeight,
        lineHeight: '18px',
        letterSpacing: '0.3px',
        padding: 0,
    },
    '& .MuiInputAdornment-root': {
        svg: {
            'path[stroke]': {
                stroke: theme.palette.primary.main,
            },
            'path[fill]': {
                fill: theme.palette.primary.main,
            },
        },
    },
    '& .MuiInputBase-root.Mui-focused': {
        background:
            'linear-gradient(0deg, rgba(239, 239, 253, 0.10) 0%, rgba(239, 239, 253, 0.20) 100%), #FFF !important',
        boxShadow: '0px 0px 16px 0px rgba(178, 164, 202, 0.50) !important',
    },
}));

export const CustomSVG = styled(ReactSVG)(({ theme }) => ({
    svg: {
        'path[fill]': {
            fill: `${theme.appPalette?.grey.grey80} !important`,
        },
        'path[stroke]': {
            stroke: `${theme.appPalette?.grey.grey80} !important`,
        },
    },
    lineHeight: 0,
}));
