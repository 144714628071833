/**
 * @description Define style attribute for WalletInfo component.
 */

export const boxContainerStyle = {
    display: 'flex',
    gap: '24px',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
};

export const titleStyle = (theme) => ({
    color: theme.appPalette?.grey.grey60,
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
});

export const valueStyle = (theme) => ({
    color: theme.appPalette?.grey.grey80,
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '28px',
});

export const boxStyle = (theme) => ({
    display: 'flex',
    padding: '16px 32px',
    height: '142px',
    width: '60%',
    alignItems: 'center',
    gap: '16px',
    background: theme.appPalette?.primary.primary8,
    borderRadius: '10px',
});

export const w50PercentStyle = {
    width: '50%',
}
