/**
 * @description Define style attribute for DatePicker component.
 */

import { styled } from '@mui/material/styles';
import { DatePicker as BaseDatePicker } from '@mui/x-date-pickers/DatePicker';
import { ReactSVG } from 'react-svg';

export const StyleDatePicker = styled(BaseDatePicker)(({ theme }) => ({
    '& .MuiInputBase-root': {
        boxShadow:
            '0px 12px 96px 0px rgba(228, 229, 252, 0.12), 0px 0px 10px 0px rgba(228, 229, 252, 0.50)',
        borderRadius: '8px',
        height: '50px',
        background: '#FFF',
        color: theme.appPalette?.grey.grey80,
        paddingRight: '24px',
    },
    '& .MuiOutlinedInput-input::placeholder': {
        color: theme.appPalette?.grey.grey60,
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .Mui-focused': {
        color: theme.appPalette?.grey.grey80,
        boxShadow: '0px 0px 16px 0px rgba(178, 164, 202, 0.50)',
    },
    '& .Mui-disabled': {
        background: theme.appPalette?.grey.grey8,
    },
    '& .MuiInputBase-input': {
        padding: '8px 24px',
    },
}));

export const CustomSVG = styled(ReactSVG)(({ theme }) => ({
    svg: {
        'path[fill]': {
            fill: theme.appPalette?.grey.grey60,
        },
        'path[stroke]': {
            stroke: theme.appPalette?.grey.grey60,
        },
    },
    lineHeight: 0,
    width: '16px',
}));

export const slotProps = (theme) => ({
    layout: {
        sx: {
            '& .MuiDayCalendar-weekDayLabel': {
                color: theme.appPalette?.grey.grey80,
            },
            '& .MuiIconButton-root': {
                color: theme.appPalette?.grey.grey80,
            },
        },
    },
    popper: {
        sx: {
            '.MuiPaper-root': {
                boxShadow:
                    '0px 12px 96px 0px rgba(228, 229, 252, 0.12), 0px 0px 10px 0px rgba(228, 229, 252, 0.50)',
                borderRadius: '8px',
                marginTop: '10px',
                marginBottom: '10px',
            },
        },
    },
    day: {
        sx: {
            '&.MuiPickersDay-root.Mui-selected': {
                backgroundColor: theme.appPalette?.primary.primary100,
                color: '#FFF',
            },
            '&.MuiPickersDay-today': {
                border: `1px solid ${theme.appPalette?.grey.grey80}`,
            },
            '&.MuiPickersDay-root': {
                color: theme.appPalette?.grey.grey80,
            },
        },
    },
});
