/**
 * @description Define the expected data types for props passed to RowsPerPage component.
 */

import { PropTypes } from 'prop-types';

export const rowsPerPagePropTypes = {
    defaultValue: PropTypes.number,
    rowsPerPage: PropTypes.array,
    totalRecords: PropTypes.number,
    firstRecordOfPage: PropTypes.number,
    lastRecordOfPage: PropTypes.number,
    onValueChange: PropTypes.func,
};
