/**
 * @description resourceResolver.js file.
 */

import { Configuration } from './configs/environment';

export default function resourceUrl(resource) {
    const baseUrl = Configuration.staticResourcesBaseURL;

    return baseUrl + resource;
}
