/**
 * @description ActionItem component.
 */

import { Grid, Typography, useTheme } from '@mui/material';
import {
    CustomSVG,
    textActionItem,
    imgGridStyle,
    itemContainer,
} from './ActionItem.style';
import { actionItemPropTypes } from 'types/actionItemPropTypes';
import { useRoutes } from 'contexts/RoutesContext';

const ActionItem = ({ imagePath, text, action }) => {
    const theme = useTheme();
    const {setIsSubMenu} = useRoutes();
    const handleClick = () => {
        if (action) {
            action();
        }
        setIsSubMenu(true);
    };
    return (
        <Grid contaier={'true'} onClick={handleClick} sx={itemContainer(theme)}>
            <Grid sx={imgGridStyle(theme)}>
                <CustomSVG src={imagePath} aria-label={text} />
            </Grid>
            <Grid item>
                <Typography sx={textActionItem(theme)}>{text}</Typography>
            </Grid>
        </Grid>
    );
};

ActionItem.propTypes = {
    ...actionItemPropTypes,
};

actionItemPropTypes.defaultProps = {};

export default ActionItem;

