/**
 * @description Define style attribute for Account component.
 */

import {styled} from "@mui/material/styles";
import {ReactSVG} from "react-svg";

export const transferIconStyle = {
    display: 'flex',
    width: '38px',
    height: '38px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    background: '#FFF',
};

export const accountBalanceStyle = {
    fontSize: '24px',
    fontStyle: 'normal',
    lineHeight: '28px',
    fontWeight: 600,
};

export const mainPanelStyle = {
    display: 'flex',
    padding: '16px 64px 16px 16px',
    alignItems: 'flex-start',
    borderRadius: '10px',
    background: '#FFF',
    boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
};

export const titleSectionStyle = {
    fontSize: '14px',
    fontWeight: 600,
    fontStyle: 'normal',
    lineHeight: '18px',
};

export const transferBoxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    alignSelf: 'stretch',
    cursor: 'pointer',
    gap: '8px',
};

export const masterGridStyle = {
    direction: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
};

export const presentBalanceGridStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: '4px',
};

export const transferGridStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    alignItems: 'center',
};

export const CustomSVG = styled(ReactSVG)(({theme}) => ({
    svg: {
        'path[fill]': {
            fill: theme.appPalette?.grey.grey80
        },
        'path[stroke]': {
            stroke: theme.appPalette?.grey.grey80
        }
    },
    lineHeight: 0
}))

export const accountInfoStyle = (theme) => ({
    color: theme.appPalette?.grey.grey60,
    fontSize: '12px',
    lineHeight: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
});
export const secondBalanceLabelStyle = (theme) => ({
    color: theme.appPalette?.grey.grey60,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '18px',
});

export const accountBalanceLabelStyle = (theme) => ({
    color: theme.appPalette?.grey.grey60,
    fontSize: '12px',
    fontWeight: 400,
    fontStyle: 'normal',
    lineHeight: '14px',
    margin: '0px 0px 4px 0px',
});

export const presentBalanceStyle = (theme) => ({
    color: theme.appPalette?.grey.grey80,
    fontSize: '14px',
    fontStyle: 'normal',
    lineHeight: '18px',
    fontWeight: 400,
});

export const transferMoneyStyle = (theme) => ({
    color: theme.appPalette?.grey.grey80,
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '14px',
});
