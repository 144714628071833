/**
 * @description AddFunds component.
 */

import React from 'react';
import { Container, Typography, Box, useTheme } from '@mui/material';
import PaymentMethodList from 'components/payment-method-list/PaymentMethodList';
import PAGE from 'constants/page';
import { containerStyle, titleStyle } from './AddFunds.style';
import PAYMENT_METHOD from 'constants/paymentMethod';
import { useTranslation } from 'react-i18next';

const AddFunds = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const items = [
        {
            id: 1,
            prefixIcon: 'images/Credit-Debit-Icon.svg',
            value: t('add-funds.credit-debit-card'),
            navigate: PAGE.ADD_FUNDS_CARD_INFORMATION,
            paymentMethod: PAYMENT_METHOD.CREDIT_CARD,
        },
    ];
    return (
        <Container sx={containerStyle}>
            <Box>
                <Typography sx={titleStyle(theme)}>
                    {t('add-funds.title')}
                </Typography>
            </Box>
            <PaymentMethodList items={items} />
        </Container>
    );
};

export default AddFunds;
