/**
 * @description Define style attribute for Dashboard component.
 */

export const containerStyle = {
    mt: '100px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    marginBottom: '60px',
};

export const networkLabelStyle = (theme) => {
    return {
        color: theme.appPalette?.grey.grey60,
        fontWeight: 600,
        lineHeight: '18px',
    };
}