/**
 * @description Define the expected data types for props passed to Pagination component.
 */

import { PropTypes } from 'prop-types';

export const paginationPropTypes = {
    defaultPage: PropTypes.number,
    totalPages: PropTypes.number,
    onValueChange: PropTypes.func,
};
