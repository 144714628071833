import TENANT from 'constants/tenant';

export const Configuration = {
    // Static resouces baseURL, remember to end it with a slash (/)
    staticResourcesBaseURL : "/",
    baseURL: 'https://api.staging.bankingcore.kardinc.com',
    tenants: [
        {
            domain: 'staging.kanoo.bankingcore.kardinc.com',
            code: TENANT.KANOO,
        },
        {
            domain: 'staging.coomeva.bankingcore.kardinc.com',
            code: TENANT.COOMEVA,
        },
        {
            domain: 'staging.bankingcore.kardinc.com',
            code: TENANT.COOMEVA,
        },
        {
            domain: 'staging.georgia.bankingcore.kardinc.com',
            code: '1007',
        },
        {
            domain: 'staging.uae.bankingcore.kardinc.com',
            code: '1008',
        },
        {
            domain: 'staging.hyt.bankingcore.kardinc.com',
            code: 'hytapp',
        }
    ]
};
