/**
 * @description Define style attribute for Withdraw component.
 */


export const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
    paddingTop: '100px',
    paddingBottom: '60px',
    width: '648px',
};

export const titleStyle = {
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '34px',
};

export const transferBoxStyle = (theme) => ({
    display: 'flex',
    padding: '20px 0px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
    borderBottom: `1px solid ${theme.appPalette?.grey.grey12}`,
    width: '100%',
});

export const transferBoxItemStyle = (isSubmit) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    opacity: isSubmit ? 0.5 : 1,
});

export const amountBoxStyle = (theme, isSubmit) => ({
    padding: '20px 0',
    display: 'flex',
    borderBottom: `1px solid ${theme.appPalette?.grey.grey12}`,
    opacity: isSubmit ? 0.5 : 1,
    justifyContent: 'space-between',
});

export const amountTitleStyle = {
    width: '50%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
};

export const amountInputStyle = {
    width: '280px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '6px',
};

export const hyperTextStyle = (theme) => ({
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.appPalette?.grey.grey60,
});

export const arrowIconStyle = {
    width: '24px',
    height: '24px',
};

export const errorStyle = (theme) => ({
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '14px',
    color: theme.appPalette?.danger,
});

export const grandTotalStyle = (theme, isSubmit) => ({
    display: 'flex',
    padding: '20px 0px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '4px',
    alignSelf: 'stretch',
    borderBottom: `1px solid ${theme.appPalette?.grey.grey12}`,
    opacity: isSubmit ? 0.5 : 1,
});

export const grandTotalItemStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
};

export const grandTotalTitleStyle = {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '36px',
    letterSpacing: '1px',
};

export const boxItemTransferStyle = {
    width: '280px',
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
}

export const boxBtnSubmit = {
    marginTop: '24px'
}
