/**
 * @description api.js file.
 */

import { Configuration } from './environment';

const Api = {
    BASE_URL: Configuration.baseURL,
    LOGIN: '/banking-core/authentication',
    VERIFY_OTP: '/banking-core/authentication/otp/verification',
    SEARCH_TRANSACTIONS: '/banking-core/transactions',
    GET_WALLET_BALANCE: '/banking-core/wallet/balance',
    GET_LINKED_ACCOUNTS: '/banking-core/linked-accounts',
    GET_ACCOUNT_BALANCE: '/banking-core/account/balance',
    NEARBY_MERCHANT_COUNT: '/banking-core/merchant/nearby',
    GET_CASH_FLOW: '/banking-core/receipt/analysis',
    GET_ACCOUNT_SUMMARY: '/banking-core/account',
    GET_CARD_LISTING: '/banking-core/wallet/subsection/listing',
    TRANSFER_MONEY: '/banking-core/transfer/conduction',
    TRANSFER_INFO: '/banking-core/transfer/info',
    GET_CREATION_INFO: '/banking-core/wallet/creation-info',
    ADD_EXTERNAL_PAYMENT_METHOD: '/banking-core/wallet/subsection',
    GET_LANGUAGES: '/banking-core/language/supported',
    GET_ACCOUNT_LISTING: '/banking-core/wallet/subsection/listing',
    GET_SUBSECTION: '/banking-core/wallet/subsection/category',
    GET_ACCOUNT_INFO: '/banking-core/user/me',
    GET_BRANDING: '/banking-core/payment/method',
    CREATE_PAYMENT_ACCOUNT: '/banking-core/wallet/subsection',
    GET_WITHDRAWAL_REQUREST_INFO:
        '/banking-core/payment/withdrawal-request/info',
    WITHDRAWAL_REQUREST: '/banking-core/payment/withdrawal-request',
};

export default Api;
