/**
 * @description Define the expected data types for props passed to Dropdown component.
 */

import PropTypes from 'prop-types';

export const dropdownPropTypes = {
    options: PropTypes.array,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
};
