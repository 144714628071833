/**
 * @description CardImage component.
 */

import { Box, Grid, Typography, useTheme } from '@mui/material';
import CardType from 'constants/cardType';

import { useTranslation } from 'react-i18next';
import { cardImagePropTypes } from 'types/cardImagePropTypes';
import resourceUrl from '../../resourceResolver';
import {
    bodyContainerStyles,
    bodyContentStyles,
    footerContainerStyles,
    footerGroupStyles,
    cardNameStyle,
    cardNumStyle,
    cardHolderStyle,
    expiryDateStyle,
    boxContainerStyle,
    bodyStyle,
    footerStyle,
    expiryBoxStyle,
    titleStyle,
    footerContentStyles,
    cardContainerStyle,
    endingInStyle,
    backgroundImageStyle,
} from './CardImage.style';

const bankCardBg = resourceUrl('images/bank-card-bg.svg');
const paypalCardBg = resourceUrl('images/paypal-card.svg');
const bancoomevaCardBg = resourceUrl('images/bancxxxxxxva-card.svg');
const creditCardBg = resourceUrl('images/credit-card-bg.svg');
const visaCardBg = resourceUrl('images/visa.svg');
const masterCardBg = resourceUrl('images/master-card.svg');
const JCBCardBg = resourceUrl('images/JCB-card.svg');
const discoverCardBg = resourceUrl('images/discover-card.svg');
const dinersClubCardBg = resourceUrl('images/diners-club-card.svg');
const kemCardBg = resourceUrl('images/kem-card.svg');
const EBTCardBg = resourceUrl('images/EBT.svg');
const dots = resourceUrl('images/dots.svg');
const sandDollarCardBg = resourceUrl('images/sand-dollar-card.svg');
const saBg = resourceUrl('images/cash-card-coomeva.svg');

const backgroundImage = {
    BANK: bankCardBg,
    BANCOOMEVA: bancoomevaCardBg,
    PAYPAL: paypalCardBg,
    CREDIT: creditCardBg,
    VISA: visaCardBg,
    MASTER_CARD: masterCardBg,
    JCB: JCBCardBg,
    DISCOVER: discoverCardBg,
    DINERS_CLUB: dinersClubCardBg,
    KEMP: kemCardBg,
    EBT: EBTCardBg,
    SD: sandDollarCardBg,
    SA: saBg,
};

const CardImage = ({
    cardType,
    cardNum,
    cardEmail,
    cardHolder,
    cardExpiry,
    cardName,
    balance,
    cardNetwork,
    cardCode,
    cardBackgroundImg,
}) => {
    const theme = useTheme();
    backgroundImage.AMERICAN_EXPRESS = theme.americanExpressCardBg;
    const backgroundUrl = backgroundImage[cardType] || bankCardBg;
    const bodyContainerStyle =
        bodyContainerStyles[cardType] || bodyContainerStyles.BANK;
    const bodyContentStyle =
        bodyContentStyles[cardType] || bodyContentStyles.BANK;
    const bodyContent = cardType === CardType.BANK ? cardNum : cardEmail;

    const footerContainerStyle =
        footerContainerStyles[cardType] || footerContainerStyles.BANK;
    const footerGroupStyle =
        footerGroupStyles[cardType] || footerGroupStyles.BANK;
    const footerContentStyle =
        footerContentStyles(theme)[cardType] || footerContentStyles(theme).BANK;
    const { t } = useTranslation();
    return cardBackgroundImg ? (
        renderCardImage(
            t,
            cardBackgroundImg,
            balance,
            cardHolder,
            cardNum,
            cardName,
        )
    ) : (
        <>
            {cardType === CardType.VISA ||
            cardType === CardType.MASTER_CARD ||
            cardType === CardType.JCB ||
            cardType === CardType.DISCOVER ||
            cardType === CardType.DINERS_CLUB ||
            cardType === CardType.AMERICAN_EXPRESS ||
            cardType === CardType.CASH_CARD ||
            cardType === CardType.KEMP ||
            cardType === CardType.EBT ||
            cardType === CardType.SA ||
            cardType === CardType.SD ? (
                <Box sx={boxContainerStyle}>
                    <>
                        {cardType === CardType.CASH_CARD ? (
                            <>
                                {cardCode === CardType.KEMP ||
                                cardCode === CardType.EBT ? (
                                    <img
                                        style={backgroundImageStyle}
                                        src={backgroundImage[cardCode]}
                                        alt={cardCode}
                                    />
                                ) : (
                                    <img
                                        style={backgroundImageStyle}
                                        src={theme.cashCardBg}
                                        alt={cardType}
                                    />
                                )}
                            </>
                        ) : (
                            <img
                                src={backgroundImage[cardType]}
                                alt={cardType}
                            />
                        )}
                    </>
                    {cardCode !== CardType.KEMP &&
                        cardCode !== CardType.EBT &&
                        cardType !== CardType.SD && (
                            <Typography sx={cardNameStyle}>
                                {cardName}
                            </Typography>
                        )}
                    {(cardType === CardType.CASH_CARD ||
                        cardType === CardType.SA ||
                        cardType === CardType.SD) && (
                        <Box
                            sx={{ ...bodyStyle, justifyContent: 'flex-start' }}
                        >
                            <Typography sx={cardNumStyle}>{balance}</Typography>
                        </Box>
                    )}
                    {cardType !== CardType.CASH_CARD &&
                        cardType !== CardType.SA &&
                        cardType !== CardType.SD && (
                            <Box sx={bodyStyle}>
                                <img src={dots} alt="dots" />
                                <img src={dots} alt="dots" />
                                <img src={dots} alt="dots" />
                                <Typography sx={cardNumStyle}>
                                    {cardNum}
                                </Typography>
                            </Box>
                        )}
                    <Box sx={footerStyle}>
                        <Typography sx={cardHolderStyle}>
                            {cardHolder}
                        </Typography>
                        {cardType === CardType.CASH_CARD ||
                        cardType === CardType.EBT ||
                        cardType === CardType.SD ||
                        cardType === CardType.SA ? (
                            <Box sx={expiryBoxStyle}>
                                <Typography
                                    sx={{
                                        ...expiryDateStyle,
                                        ...endingInStyle,
                                    }}
                                >
                                    {t('card-information.ending-in')}
                                </Typography>
                                <Typography sx={cardHolderStyle}>
                                    {cardNum}
                                </Typography>
                            </Box>
                        ) : (
                            <Box sx={expiryBoxStyle}>
                                <Typography sx={expiryDateStyle}>
                                    {t('card-information.expiry-date')}
                                </Typography>
                                <Typography sx={cardHolderStyle}>
                                    {cardExpiry}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
            ) : (
                <Grid
                    container
                    sx={cardContainerStyle(backgroundUrl, cardType)}
                >
                    <Grid item xs={12}>
                        <Typography sx={titleStyle}>{cardName}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={bodyContainerStyle}>
                        <Typography sx={bodyContentStyle}>
                            {bodyContent}
                        </Typography>
                    </Grid>
                    <Grid container item xs={12} spacing={1}>
                        <Grid container item sx={footerContainerStyle}>
                            {cardType === CardType.BANK && (
                                <Grid
                                    container
                                    item
                                    xs={4.5}
                                    spacing={0.5}
                                    direction="column"
                                    sx={footerGroupStyle}
                                >
                                    <Grid item>
                                        <Typography sx={footerContentStyle}>
                                            {t('card-information.card-holder')}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            sx={{
                                                ...footerContentStyle,
                                                opacity: '1',
                                            }}
                                        >
                                            {cardHolder}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {cardType === CardType.BANK && (
                                <Grid
                                    container
                                    item
                                    xs={4.5}
                                    spacing={0.5}
                                    direction="column"
                                    sx={footerGroupStyle}
                                >
                                    <Grid item>
                                        <Typography sx={footerContentStyle}>
                                            {t('card-information.expiry-date')}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography
                                            sx={{
                                                ...footerContentStyle,
                                                opacity: '1',
                                            }}
                                        >
                                            {cardExpiry}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {cardType === CardType.BANCOOMEVA && (
                                <Grid container item sx={footerGroupStyle}>
                                    <Grid item>
                                        <Typography sx={footerContentStyle}>
                                            {cardHolder}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                            {cardType === CardType.CREDIT && (
                                <Grid container item sx={footerGroupStyle}>
                                    <Grid item>
                                        <Typography sx={footerContentStyle}>
                                            {cardHolder}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

const renderCardImage = (
    t,
    cardBackgroundImg,
    balance,
    cardHolder,
    cardNum,
    cardName,
) => {
    return (
        <Box sx={boxContainerStyle}>
            <Typography sx={cardNameStyle}>{cardName}</Typography>
            <img style={backgroundImageStyle} src={cardBackgroundImg} alt="" />
            <Box sx={{ ...bodyStyle, justifyContent: 'flex-start' }}>
                <Typography sx={cardNumStyle}>{balance}</Typography>
            </Box>
            <Box sx={footerStyle}>
                <Typography sx={cardHolderStyle}>{cardHolder}</Typography>
                <Box sx={expiryBoxStyle}>
                    <Typography
                        sx={{
                            ...expiryDateStyle,
                            ...endingInStyle,
                        }}
                    >
                        {t('card-information.ending-in')}
                    </Typography>
                    <Typography sx={cardHolderStyle}>{cardNum}</Typography>
                </Box>
            </Box>
        </Box>
    );
};

CardImage.propTypes = {
    ...cardImagePropTypes,
};

export default CardImage;

