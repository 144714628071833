/**
 * @description Define style attribute for LanguageSelector component.
 */

export const boxContainerStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
};

export const boxStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch',
};

export const titleStyle = (theme) => ({
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '34px',
    color: theme.appPalette?.grey.grey80,
});

export const descStyle = (theme) => ({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px', 
    color: theme.appPalette?.grey.grey60
})

export const boxPreferredStyle = {
    display: 'flex',
    gap: '24px',
}

export const boxItemStyle = {
    width: '50%',
    height: '50px',
    display: 'flex',
    alignItems: 'center',
}

export const boxItemLanguageStyle = {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
}

export const titlePreferredLngStyle = (theme) => ({
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
    color: theme.appPalette?.grey.grey80,
});

export const descPreferredLngStyle = (theme) => ({
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.appPalette?.grey.grey60,
});