/**
 * @description LoginForm component.
 */

import React from 'react';
import Box from '@mui/material/Box';
import { Typography, useTheme } from '@mui/material';
import Button from 'components/button/Button';
import Input from 'components/input/Input';
import Password from 'components/password/Password';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'hooks/useAuth';
import {
    logoStyle,
    inlineErrorStyle,
    inputPanelStyle,
    formTitleStyle,
    formStyles,
    imageContainerStyle,
} from './LoginForm.style';

const LoginForm = () => {
    const { t } = useTranslation();

    const theme = useTheme();
    const [apiErrorMessage, setApiErrorMessage] = React.useState('');
    const { control, handleSubmit } = useForm();
    const { login } = useAuth();
    // create apiClient instance for request API
    const onSubmit = async (data) => {
        try {
            await login(data);
        } catch (err) {
            setApiErrorMessage(err.message);
        }
    };
    return (
        <form style={formStyles} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={imageContainerStyle}>
                <img src={theme.logoImage} alt="Logo" style={logoStyle} />
            </Box>
            <Typography sx={formTitleStyle}>
                {t('login-form.welcome-back')}
            </Typography>
            <Box>
                <Typography sx={inputPanelStyle}>
                    {t('login-form.email')}
                </Typography>
                <Controller
                    name="email"
                    control={control}
                    rules={{
                        required: t('form.validations.email.required'),
                    }}
                    render={({ field, fieldState }) => (
                        <>
                            <Input
                                defaultValue=""
                                onValueChange={(value) => {
                                    field.onChange(value);
                                    setApiErrorMessage('');
                                }}
                                inputProps={{
                                    'data-testid': 'login-email',
                                }}
                            />
                            {fieldState.error && !apiErrorMessage && (
                                <Typography sx={inlineErrorStyle}>
                                    {fieldState.error.message}
                                </Typography>
                            )}
                        </>
                    )}
                />
            </Box>
            <Box>
                <Typography sx={inputPanelStyle}>
                    {t('login-form.password')}
                </Typography>
                <Controller
                    name="password"
                    control={control}
                    rules={{
                        required: t('form.validations.password.required'),
                    }}
                    render={({ field, fieldState }) => (
                        <>
                            <Password
                                defaultValue=""
                                onValueChange={(value) => {
                                    field.onChange(value);
                                    setApiErrorMessage('');
                                }}
                                inputProps={{
                                    'data-testid': 'login-password',
                                }}
                            />
                            {fieldState.error && !apiErrorMessage && (
                                <Typography sx={inlineErrorStyle}>
                                    {fieldState.error.message}
                                </Typography>
                            )}
                        </>
                    )}
                />
            </Box>
            {apiErrorMessage && (
                <Typography sx={inlineErrorStyle}>{apiErrorMessage}</Typography>
            )}
            <Button
                type="submit"
                variant="contained"
                label={t('login-form.sign-in')}
            ></Button>
        </form>
    );
};

export default LoginForm;

