/**
 * @description SidebarItem component.
 */

import { MenuItem } from 'react-pro-sidebar';
import { useNavigate } from 'react-router-dom';
import { useTheme, Box } from '@mui/material';
import { itemStyle, StyledTypography } from './SidebarItem.style';
import { sideBarItemPropTypes } from 'types/sideBarItemPropTypes';

const SidebarItem = ({ title, to, icon, selected, state, ...props }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    return (
        <Box onClick={() => navigate(to, { state: state })}>
            <MenuItem
                tabIndex={-1}
                icon={icon}
                active={selected}
                style={{
                    height: '40px',
                }}
                rootStyles={itemStyle(theme)}
                key={to}
                {...props}
            >
                <StyledTypography selected={selected}>{title}</StyledTypography>
            </MenuItem>
        </Box>
    );
};

SidebarItem.propTypes = {
    ...sideBarItemPropTypes,
};

export default SidebarItem;

