/**
 * @description Define the expected data types for props passed to SearchBox component.
 */

import { PropTypes } from 'prop-types';

export const searchBoxPropTypes = {
    placeholder: PropTypes.string,
    filterAction: PropTypes.func,
    searchAction: PropTypes.func,
};
