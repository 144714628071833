/**
 * @description CardInformationForm component.
 */

import React, { useRef, useState, useEffect } from 'react';
import { Container, Box, Typography, useTheme } from '@mui/material';
import PageTitle from 'components/page-title/PageTitle';
import {
    containerStyle,
    descStyle,
    boxItemStyle,
    boxItemFormStyle,
    labelStyle,
    hyperTextStyle,
    inputStyle,
    boxCardNumberStyle,
    boxItemCardNumberStyle,
    errorStyle,
    requiredStyle,
} from './CardInformationForm.style';
import { useNavigate, useLocation } from 'react-router-dom';
import PAGE from 'constants/page';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Input from 'components/input/Input';
import Button from 'components/button/Button';
import DatePicker from 'components/date-picker/DatePicker';
import INPUT_FORMAT from 'constants/inputFormat';
import { useToast } from 'contexts/ToastContext';
import WalletService from 'services/wallet/walletService';
import { useAuth } from 'hooks/useAuth';
import PAYMENT_METHOD from 'constants/paymentMethod';
import { formatISO } from 'date-fns';
import Popup from '../../components/pop-up/Popup';

const CONSTANTS = {
    VALIDATION_RULES: {
        REQUIRED: 'required',
        MIN_LENGTH: 'minLength',
        MAX_LENGTH: 'maxLength'
    },
    MIN_LENGTH: {
        CARD_NUMBER: 13,
        CVV_CVC: 3
    },
    MAX_LENGTH: {
        CARD_HOLDER_NAME: 50,
        CARD_NUMBER: 19,
        CVV_CVC: 4,
        ZIP_CODE: 20,
        NICK_NAME: 50
    }
};

const CardInformationForm = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { jwtToken } = useAuth();
    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm();
    const { t } = useTranslation();
    const cardNumber = useRef({});
    cardNumber.current = watch('cardNumber');
    const confirmCardNumber = useRef({});
    confirmCardNumber.current = watch('confirmCardNumber');
    const { showToast } = useToast();
    const [loading, setLoading] = useState(false);
    const { state } = useLocation();
    const [confirmCardNumberErrMsg, setConfirmCardNumberErrMsg] = useState('');
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [errorMsg, setErrorMsg] = useState({title: t('add-funds.error.error-message-title')});

    const redirectToDashboard = () => {
        navigate(PAGE.DASHBOARD);
    };

    useEffect(() => {
        if (!state) {
            redirectToDashboard();
        }
    }, []);

    const onSubmit = (data) => {
        setLoading(true);
        const date = new Date(data.expiry);
        const year = date.getFullYear();
        const month = date.getMonth();
        const newDate = new Date(year, month); 
        const expiry = formatISO(newDate, { representation: 'date' });
        const body = {
            paymentMethod: PAYMENT_METHOD.CREDIT_CARD,
            cardHolderName: data.cardHolderName.trim(),
            cardNumber: data.cardNumber,
            expiry: expiry,
            cvv: data.cvv,
            zipCode: data.zipCode,
            nickName: data.nickName?.trim() || '',
        };
        WalletService.addExternalPaymentMethod(jwtToken, body).then(
            (response) => {
                if (response?.isSuccess) {
                    showToast(
                        true,
                        false,
                        false,
                        false,
                        '',
                        t(
                            'add-funds.card-information.toast-message.added-successfully',
                        ),
                        '360px',
                    );
                    navigate(PAGE.DASHBOARD);
                } else {
                    if (response?.result) {
                        setErrorMsg({title: errorMsg.title, message: t('add-funds.error.error-message-content'), extraInfo: response?.result.extraInfo3SP});
                    } else {
                        setErrorMsg({title: errorMsg.title, message: t('error-message.general-error')});
                    }             
                    setIsShowPopup(true);
                }
                setLoading(false);
            },
        );
    };
    useEffect(() => {
        const cardNumberValue = cardNumber.current;
        const confirmCardNumberValue = confirmCardNumber.current;
        if (cardNumberValue && confirmCardNumberValue) {
            if (cardNumberValue !== confirmCardNumberValue) {
                setConfirmCardNumberErrMsg(
                    t('validation.does-not-match', {
                        field: t(
                            'add-funds.card-information.confirm-card-number',
                        ),
                    }),
                );
            } else {
                setConfirmCardNumberErrMsg('');
            }
        }
    }, [cardNumber.current, confirmCardNumber.current]);
    return (
        <>
            {state?.allowCreation && (
                <Container sx={containerStyle(loading)}>
                    {isShowPopup && (
                        <Popup
                            open={isShowPopup}
                            title={errorMsg?.title}
                            content={errorMsg?.message}
                            viewDetails={errorMsg?.extraInfo}
                            onClose={() => {
                                setIsShowPopup(false);
                            }}
                            onAgree={() => {
                                setIsShowPopup(false);
                            }}
                        />
                    )}
                    <Box sx={boxItemStyle}>
                        <PageTitle
                            title={t('add-funds.card-information.title')}
                            onClick={() => navigate(PAGE.ADD_FUNDS)}
                        />
                        <Typography sx={descStyle(theme)}>
                            {t('add-funds.card-information.desc')}
                        </Typography>
                    </Box>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box
                            sx={{ ...boxItemFormStyle(theme), paddingTop: '0' }}
                        >
                            <Box display="flex">
                                <Typography sx={labelStyle(theme)}>
                                    {t(
                                        'add-funds.card-information.name-on-card',
                                    )}
                                </Typography>
                                <Typography sx={requiredStyle}>*</Typography>
                            </Box>
                            <Box sx={inputStyle}>
                                <Controller
                                    name="cardHolderName"
                                    control={control}
                                    rules={{
                                        required: true,
                                        maxLength: CONSTANTS.MAX_LENGTH.CARD_HOLDER_NAME,
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            readOnly={loading}
                                            defaultValue=""
                                            placeholder="..."
                                            format={
                                                INPUT_FORMAT
                                                    .ALPHABETICAL_CHARACTER_SPACE
                                                    .name
                                            }
                                            onValueChange={(value) =>
                                                field.onChange(value)
                                            }
                                        />
                                    )}
                                />
                                <Typography sx={hyperTextStyle(theme)}>
                                    {t('add-funds.card-information.up-to', {
                                        maxLength: CONSTANTS.MAX_LENGTH.CARD_HOLDER_NAME,
                                    })}
                                </Typography>
                                {errors.cardHolderName?.type === CONSTANTS.VALIDATION_RULES.REQUIRED && (
                                    <Typography sx={errorStyle}>
                                        {t('validation.required', {
                                            field: t(
                                                'add-funds.card-information.name-on-card',
                                            ),
                                        })}
                                    </Typography>
                                )}
                                {errors.cardHolderName?.type ===
                                    CONSTANTS.VALIDATION_RULES.MAX_LENGTH && (
                                    <Typography sx={errorStyle}>
                                        {t('validation.max-length', {
                                            maxLength: CONSTANTS.MAX_LENGTH.CARD_HOLDER_NAME,
                                        })}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={boxCardNumberStyle(theme)}>
                            <Box sx={boxItemCardNumberStyle}>
                                <Box display="flex">
                                    <Typography sx={labelStyle(theme)}>
                                        {t(
                                            'add-funds.card-information.card-number',
                                        )}
                                    </Typography>
                                    <Typography sx={requiredStyle}>
                                        *
                                    </Typography>
                                </Box>
                                <Box sx={inputStyle}>
                                    <Controller
                                        name="cardNumber"
                                        control={control}
                                        rules={{
                                            required: true,
                                            maxLength: CONSTANTS.MAX_LENGTH.CARD_NUMBER,
                                            minLength: CONSTANTS.MIN_LENGTH.CARD_NUMBER,
                                        }}
                                        render={({ field }) => (
                                            <Input
                                                readOnly={loading}
                                                defaultValue=""
                                                placeholder="XXXX-XXXX-XXXX-XXXX"
                                                onValueChange={(value) =>
                                                    field.onChange(
                                                        value.replaceAll(
                                                            '-',
                                                            '',
                                                        ),
                                                    )
                                                }
                                                format={
                                                    INPUT_FORMAT
                                                        .CARD_NUMBER_FORMAT.name
                                                }
                                            />
                                        )}
                                    />
                                    {errors.cardNumber?.type === CONSTANTS.VALIDATION_RULES.REQUIRED && (
                                        <Typography sx={errorStyle}>
                                            {t('validation.required', {
                                                field: t(
                                                    'add-funds.card-information.card-number',
                                                ),
                                            })}
                                        </Typography>
                                    )}
                                    {errors.cardNumber?.type ===
                                        CONSTANTS.VALIDATION_RULES.MAX_LENGTH && (
                                        <Typography sx={errorStyle}>
                                            {t('validation.max-length', {
                                                maxLength: CONSTANTS.MAX_LENGTH.CARD_NUMBER,
                                            })}
                                        </Typography>
                                    )}
                                    {errors.cardNumber?.type ===
                                        CONSTANTS.VALIDATION_RULES.MIN_LENGTH && (
                                        <Typography sx={errorStyle}>
                                            {t('validation.min-length', {
                                                minLength: CONSTANTS.MIN_LENGTH.CARD_NUMBER,
                                            })}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            <Box sx={boxItemCardNumberStyle}>
                                <Box display="flex">
                                    <Typography sx={labelStyle(theme)}>
                                        {t(
                                            'add-funds.card-information.confirm-card-number',
                                        )}
                                    </Typography>
                                    <Typography sx={requiredStyle}>
                                        *
                                    </Typography>
                                </Box>
                                <Box sx={inputStyle}>
                                    <Controller
                                        name="confirmCardNumber"
                                        control={control}
                                        rules={{
                                            required: true,
                                            validate: (value) =>
                                                value === cardNumber.current,
                                        }}
                                        render={({ field }) => (
                                            <Input
                                                readOnly={loading}
                                                defaultValue=""
                                                placeholder="XXXX-XXXX-XXXX-XXXX"
                                                onValueChange={(value) =>
                                                    field.onChange(
                                                        value.replaceAll(
                                                            '-',
                                                            '',
                                                        ),
                                                    )
                                                }
                                                format={
                                                    INPUT_FORMAT
                                                        .CARD_NUMBER_FORMAT.name
                                                }
                                            />
                                        )}
                                    />
                                    {errors.confirmCardNumber?.type ===
                                    CONSTANTS.VALIDATION_RULES.REQUIRED ? (
                                        <Typography sx={errorStyle}>
                                            {t('validation.required', {
                                                field: t(
                                                    'add-funds.card-information.confirm-card-number',
                                                ),
                                            })}
                                        </Typography>
                                    ) : (
                                        confirmCardNumberErrMsg && (
                                            <Typography sx={errorStyle}>
                                                {confirmCardNumberErrMsg}
                                            </Typography>
                                        )
                                    )}
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={boxItemFormStyle(theme)}>
                            <Box display="flex">
                                <Typography sx={labelStyle(theme)}>
                                    {t('add-funds.card-information.expiry')}
                                </Typography>
                                <Typography sx={requiredStyle}>*</Typography>
                            </Box>
                            <Box sx={inputStyle}>
                                <Controller
                                    name="expiry"
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <DatePicker
                                            format={'MM/YYYY'}
                                            views={['month', 'year']}
                                            readOnly={true}
                                            onChange={(value) =>
                                                field.onChange(value)
                                            }
                                        />
                                    )}
                                />
                                {errors.expiry?.type === CONSTANTS.VALIDATION_RULES.REQUIRED && (
                                    <Typography sx={errorStyle}>
                                        {t('validation.required', {
                                            field: t(
                                                'add-funds.card-information.expiry',
                                            ),
                                        })}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={boxItemFormStyle(theme)}>
                            <Box display="flex">
                                <Typography sx={labelStyle(theme)}>
                                    {t('add-funds.card-information.cvv-cvc')}
                                </Typography>
                                <Typography sx={requiredStyle}>*</Typography>
                            </Box>
                            <Box sx={inputStyle}>
                                <Controller
                                    name="cvv"
                                    control={control}
                                    rules={{
                                        required: true,
                                        minLength: CONSTANTS.MIN_LENGTH.CVV_CVC,
                                        maxLength: CONSTANTS.MAX_LENGTH.CVV_CVC,
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            readOnly={loading}
                                            defaultValue=""
                                            placeholder="..."
                                            onValueChange={(value) =>
                                                field.onChange(value)
                                            }
                                            format={INPUT_FORMAT.NUMERIC.name}
                                            inputProps={{
                                                'data-testid': 'cvv',
                                            }}
                                        />
                                    )}
                                />
                                {errors.cvv?.type === CONSTANTS.VALIDATION_RULES.REQUIRED && (
                                    <Typography sx={errorStyle}>
                                        {t('validation.required', {
                                            field: t(
                                                'add-funds.card-information.cvv-cvc',
                                            ),
                                        })}
                                    </Typography>
                                )}
                                {errors.cvv?.type === CONSTANTS.VALIDATION_RULES.MIN_LENGTH && (
                                    <Typography sx={errorStyle}>
                                        {t('validation.min-length', {
                                            minLength: CONSTANTS.MIN_LENGTH.CVV_CVC,
                                        })}
                                    </Typography>
                                )}
                                {errors.cvv?.type === CONSTANTS.VALIDATION_RULES.MAX_LENGTH && (
                                    <Typography sx={errorStyle}>
                                        {t('validation.max-length', {
                                            maxLength: CONSTANTS.MAX_LENGTH.CVV_CVC,
                                        })}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={boxItemFormStyle(theme)}>
                            <Box display="flex">
                                <Typography sx={labelStyle(theme)}>
                                    {t('add-funds.card-information.zip-code')}
                                </Typography>
                                <Typography sx={requiredStyle}>*</Typography>
                            </Box>
                            <Box sx={inputStyle}>
                                <Controller
                                    name="zipCode"
                                    control={control}
                                    rules={{
                                        required: true,
                                        maxLength: CONSTANTS.MAX_LENGTH.ZIP_CODE,
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            readOnly={loading}
                                            defaultValue=""
                                            placeholder="..."
                                            onValueChange={(value) =>
                                                field.onChange(value)
                                            }
                                            format={
                                                INPUT_FORMAT.NUMERIC_HYPHEN.name
                                            }
                                        />
                                    )}
                                />
                                <Typography sx={hyperTextStyle(theme)}>
                                    {t(
                                        'add-funds.card-information.zip-code-desc',
                                    )}
                                </Typography>
                                {errors.zipCode?.type === CONSTANTS.VALIDATION_RULES.REQUIRED && (
                                    <Typography sx={errorStyle}>
                                        {t('validation.required', {
                                            field: t(
                                                'add-funds.card-information.zip-code',
                                            ),
                                        })}
                                    </Typography>
                                )}
                                {errors.zipCode?.type === CONSTANTS.VALIDATION_RULES.MAX_LENGTH && (
                                    <Typography sx={errorStyle}>
                                        {t('validation.max-length', {
                                            maxLength: CONSTANTS.MAX_LENGTH.ZIP_CODE,
                                        })}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={boxItemFormStyle(theme)}>
                            <Typography sx={labelStyle(theme)}>
                                {t(
                                    'add-funds.card-information.account-nickname',
                                )}
                            </Typography>
                            <Box sx={inputStyle}>
                                <Controller
                                    name="nickName"
                                    control={control}
                                    rules={{
                                        maxLength: CONSTANTS.MAX_LENGTH.NICK_NAME,
                                    }}
                                    render={({ field }) => (
                                        <Input
                                            readOnly={loading}
                                            defaultValue=""
                                            placeholder="..."
                                            onValueChange={(value) =>
                                                field.onChange(value)
                                            }
                                            format={
                                                INPUT_FORMAT
                                                    .ALPHABETICAL_CHARACTER_SPACE
                                                    .name
                                            }
                                        />
                                    )}
                                />
                                <Typography sx={hyperTextStyle(theme)}>
                                    {t('add-funds.card-information.up-to', {
                                        maxLength: CONSTANTS.MAX_LENGTH.NICK_NAME,
                                    })}
                                </Typography>
                                {errors.nickName?.type === CONSTANTS.VALIDATION_RULES.MAX_LENGTH && (
                                    <Typography sx={errorStyle}>
                                        {t('validation.max-length', {
                                            maxLength: CONSTANTS.MAX_LENGTH.NICK_NAME,
                                        })}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                        <Box sx={{ paddingTop: '24px' }}>
                            <Button
                                type="submit"
                                disabled={loading}
                                loading={loading}
                                variant="contained"
                                label="Submit"
                            />
                        </Box>
                    </form>
                </Container>
            )}
        </>
    );
};

export default CardInformationForm;

