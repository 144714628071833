/**
 * @description Define style attribute for OtpConfirmationForm component.
 */

import { MuiOtpInput } from 'mui-one-time-password-input';
import { styled } from '@mui/material/styles';
import { ReactSVG } from 'react-svg';

export const CustomOtpInput = styled(MuiOtpInput)(({ theme }) => ({
    '& .MuiOutlinedInput-input': {
        padding: '0px',
    },
    '& .MuiInputBase-formControl': {
        width: '64px',
        height: '64px',
        fontSize: '40px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '40px',
    },
    '& .Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
            borderBottom: `2px solid ${theme.appPalette?.grey.grey80} !important`,
        },
    },
    '& .MuiOutlinedInput-notchedOutline': {
        borderTop: 0,
        borderLeft: 0,
        borderRight: 0,
        borderBottom: `2px solid ${theme.appPalette?.grey.grey12}`,
        borderRadius: '0px',
    },
    '& .MuiInputBase-input': {
        color: theme.appPalette?.grey.grey80,
    },
}));
export const CustomSVG = styled(ReactSVG)(({ theme }) => ({
    svg: {
        'path[stroke]': {
            stroke: theme.appPalette?.grey.grey80,
        },
        'path[fill]': {
            fill: theme.appPalette?.grey.grey80,
        },
    },
}));
export const logoContainerStyle = {
    marginBottom: '12px',
};
export const logoStyle = {
    width: '262px',
    height: '145px',
};
export const titleStyle = {
    display: 'flex',
    gap: '16px',
};
export const iconBackStyle = {
    width: '40px',
    height: '40px',
    cursor: 'pointer',
};
export const errorContainerStyle = {
    padding: '8px 0',
};
export const countDownCaptionStyle = {
    marginTop: '4px',
};

export const otpContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    padding: '0 16px',
    gap: '60px',
};

export const inputStyle = (theme) => ({
    width: '60px',
    height: '60px',
    outline: 'none',
    borderTop: '0px',
    borderLeft: '0px',
    borderRight: '0px',
    borderBottom: '2px solid ' + theme.appPalette?.grey.grey12,
    background: 'transparent',
    fontSize: '40px',
    lineHeight: '40px',
    fontWeight: 600,
    color: theme.appPalette?.grey.grey80,
    fontFamily: 'Avenir Next LT Pro',
    textAlign: 'center',
});

export const focusStyle = (theme) => ({
    borderBottom: '2px solid ' + theme.appPalette?.grey.grey80,
});
