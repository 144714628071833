/**
 * @description Define the expected data types for props passed to CardAccount component.
 */

import PropTypes from 'prop-types';

export const cardAccountCustomComboBoxPropTypes = {
    options: PropTypes.array,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.string,
    label: PropTypes.string,
    isSvgImage: PropTypes.bool,
    onChangeValue: PropTypes.func,
};
