/**
 * @description Define style attribute for ActionGroup component.
 */

export const boxContentStyle = (theme, width, isCenter, isSpaceEvenly) => ({
    display: 'flex',
    padding: '16px 32px',
    alignItems: 'center',
    gap: '40px',
    borderRadius: '10px',
    background: theme.appPalette?.primary.primary8,
    height: '142px',
    width: width,
    justifyContent: getJustifyContent(isCenter, isSpaceEvenly),
});

function getJustifyContent(isCenter, isSpaceEvenly) {
    if (isCenter) {
        return 'center';
    } else if (isSpaceEvenly) {
        return 'space-evenly';
    } else {
        return 'space-between';
    }
}

