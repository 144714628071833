/**
 * @description Define style attribute for CardImage component.
 */

import CARD_TYPE from 'constants/cardType';

export const backgroundStyle = {
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    width: '277px',
    minWidth: '277px',
    height: '175px',
    minHeight: '175px',
    padding: '12px 16px',
    borderRadius: '15px',
};

export const bodyContainerStyles = {
    BANK: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'end',
    },
    BANCOOMEVA: {
        display: 'none',
    },
    PAYPAL: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'start',
        marginTop: '-22px',
    },
};

export const bodyContentStyles = {
    BANK: {
        color: '#FFF',
        textAlign: 'center',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '24px',
    },
    BANCOOMEVA: {
        display: 'none',
    },
    PAYPAL: {
        color: '#FFF',
        textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '20px',
    },
};

export const footerContainerStyles = {
    BANK: {
        display: 'flex',
    },
    BANCOOMEVA: {
        display: 'flex',
    },
    PAYPAL: {
        display: 'none',
    },
};

export const footerGroupStyles = {
    BANK: {
        justifyContent: 'flex-end',
    },
    BANCOOMEVA: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'space-between',
    },
    PAYPAL: {
        display: 'none',
    },
    CREDIT: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'space-between',
    },
};

export const cardNameStyle = {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
    color: '#FFF',
    position: 'absolute',
    top: '16px',
    left: '16px',
};

export const cardNumStyle = {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '28px',
    color: '#FFF',
};

export const cardHolderStyle = {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
    color: '#FFF',
    textShadow: '0px 0px 6px rgba(0, 0, 0, 0.30)',
};

export const expiryDateStyle = {
    fontSize: '11px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '12px',
    color: '#FFF',
    textShadow: '0px 0px 6px rgba(0, 0, 0, 0.30)',
};

export const boxContainerStyle = {
    position: 'relative',
    width: '277px',
    height: '175px',
};

export const bodyStyle = {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    gap: '16px',
    alignItems: 'center',
    top: '73px',
    width: '245px',
    left: '16px',
};

export const footerStyle = {
    position: 'absolute',
    bottom: '16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
    width: '245px',
    left: '16px',
};

export const expiryBoxStyle = {
    textAlign: 'end',
};

export const titleStyle = {
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '20px',
    color: '#FFF',
    marginTop: '4px',
};

export const footerContentStyles = (theme) => ({
    BANK: {
        color: '#FFF',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '18px',
    },
    BANCOOMEVA: {
        color: '#FFF',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '18px',
    },
    PAYPAL: {
        display: 'none',
    },
    CREDIT: {
        color: theme.appPalette?.grey.grey80,
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '18px',
    },
});

export const cardContainerStyle = (backgroundUrl, cardType) => ({
    ...backgroundStyle,
    backgroundImage: `url(${backgroundUrl})`,
    boxShadow:
        cardType === CARD_TYPE.CREDIT
            ? ' 0px 0px 8px 0px rgba(0, 0, 0, 0.20)'
            : 'none',
});

export const backgroundImageStyle = {
    width: '277px',
    height: '175px',
};

export const endingInStyle = {
    textShadow: '0px 0px 6px rgba(0, 0, 0, 0.30)',
    fontSize: '8px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '4px',
    textTransform: 'uppercase',
}