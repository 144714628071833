/**
 * @description PointNetworkItem component.
 */

import { Grid, useTheme } from '@mui/material';
import React from 'react';
import OverflowTip from '../tooltip/OverflowTip';
import {
    componentStyle,
    titleStyle,
    contentStyle,
    imageContainerStyle,
    imageStyle,
    captionStyle,
    contentGroupStyle,
    tooltipStyle,
} from './PointNetworkItem.style';
import { pointNetworkItemPropTypes } from 'types/pointNetworkItemPropTypes';

const PointNetworkItem = ({ title, content, caption, imagePath }) => {
    const theme = useTheme();
    return (
        <Grid container item sx={componentStyle(theme)}>
            <Grid container item xs={8.5}>
                <OverflowTip
                    title={title}
                    textStyle={titleStyle(theme)}
                ></OverflowTip>
                <Grid item xs={12} style={contentGroupStyle}>
                    <OverflowTip
                        title={content}
                        textStyle={contentStyle(theme)}
                    ></OverflowTip>
                    <OverflowTip
                        title={caption}
                        tooltipStyle={tooltipStyle(theme)}
                        textStyle={captionStyle(theme)}
                    ></OverflowTip>
                </Grid>
            </Grid>
            <Grid container item xs={3.5} sx={imageContainerStyle}>
                <img src={imagePath} alt="imageItem" style={imageStyle} />
            </Grid>
        </Grid>
    );
};

PointNetworkItem.propTypes = {
    ...pointNetworkItemPropTypes,
};

export default PointNetworkItem;

