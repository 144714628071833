/**
 * @description Dashboard component.
 */

import AccountSummary from 'components/account-summary/AccountSummary';
import CashFlow from 'components/cash-flow/CashFlow';
import Network from 'components/network/Network';
import SearchBox from 'components/search-box/SearchBox';
import TransactionList from 'components/transaction-list/TransactionList';
import WalletInfo from 'components/wallet-info/WalletInfo';
import React, { useEffect, useState } from 'react';
import { Container, Typography, useTheme } from '@mui/material';
import CardSlider from 'components/card-slider/CardSlider';
import { useAuth } from 'hooks/useAuth';
import { createSearchParams, useNavigate } from 'react-router-dom';
import PAGE from 'constants/page';
import { useTranslation } from 'react-i18next';
import WalletService from 'services/wallet/walletService';
import { getAccountSummary } from 'services/accounting/accountingService';
import ACCOUNT_TYPE from 'constants/accountType';
import CardListing from 'components/card-listing/CardListing';
import { networkLabelStyle, containerStyle } from './Dashboard.style';
import { Box } from '@mui/system';
import { TRANSFER_STATE } from '../../constants/transferState';

const Dashboard = () => {
    const { t } = useTranslation();
    const { jwtToken } = useAuth();
    const navigate = useNavigate();
    const [walletFinancialSummary, setWalletFinancialSummary] = useState();
    const [accountSummaries, setAccountSummaries] = useState([]);
    const walletInfoData = {
        financialSummary: [
            { id: 1, category: t('wallet-info.total-assets'), amount: '$0.00' },
            {
                id: 2,
                category: t('wallet-info.available-cash'),
                amount: '$0.00',
            },
        ],
        transferActionGroup: [
            {
                imagePath: '/images/load.svg',
                text: t('wallet-info.load'),
                action: () =>
                    navigate(PAGE.LOAD, {
                        state: { flow: TRANSFER_STATE.LOAD },
                    }),
            },
            {
                imagePath: '/images/withdraw.svg',
                text: t('wallet-info.withdraw'),
                action: () => navigate(PAGE.WITHDRAW),
            },
            {
                imagePath: '/images/transfer-icon.svg',
                text: t('wallet-info.transfer'),
                action: () =>
                    navigate(PAGE.TRANSFER, {
                        state: { flow: TRANSFER_STATE.TRANSFER },
                    }),
            },
        ],
    };
    const searchTransactions = async (data) => {
        if (data && data.trim().length > 0) {
            navigate({
                pathname: PAGE.TRANSACTIONS,
                search: `?${createSearchParams({ term: data })}`,
            });
        }
    };
    const getWalletInfo = () => {
        WalletService.getWalletBalance(jwtToken).then((response) => {
            if (response) {
                const { totalAssets, availableCash } = response;
                walletInfoData.financialSummary[0].amount = totalAssets;
                walletInfoData.financialSummary[0].category = t('wallet-info.total-assets');
                walletInfoData.financialSummary[1].amount = availableCash;
                walletInfoData.financialSummary[1].category = t('wallet-info.available-cash');
                setWalletFinancialSummary(walletInfoData.financialSummary);
            }
        });
    };
    const getAccount = () => {
        // Fetch bank account summary
        getAccountSummary(jwtToken, ACCOUNT_TYPE.BANK).then((response) => {
            if (response) {
                const {
                    accountId,
                    accountName,
                    last4,
                    availableBalance,
                    presentBalance,
                } = response;
                accountSummaries[0] = {
                    id: accountId,
                    data: {
                        title: t('account-summary.bank-account.title'),
                        accountName: accountName.toUpperCase(),
                        last4: last4,
                        availableBalance: availableBalance,
                        presentBalance: presentBalance,
                        secondBalanceLabel: t(
                            'account-summary.bank-account.second-balance-label',
                        ),
                    },
                    viewAction: () => alert('View all was clicked!'),
                    transferAction: () => alert('Transfer button was clicked!'),
                };
            }
        });
        // Fetch saving account summary
        getAccountSummary(jwtToken, ACCOUNT_TYPE.SAVING).then((response) => {
            if (response) {
                const {
                    accountId,
                    accountName,
                    last4,
                    availableBalance,
                    presentBalance,
                } = response;
                accountSummaries[1] = {
                    id: accountId,
                    data: {
                        title: t('account-summary.saving-account.title'),
                        accountName: accountName.toUpperCase(),
                        last4: last4,
                        availableBalance: availableBalance,
                        presentBalance: presentBalance,
                        secondBalanceLabel: t(
                            'account-summary.bank-account.second-balance-label',
                        ),
                    },
                    viewAction: () => alert('View all was clicked!'),
                    transferAction: () => alert('Transfer button was clicked!'),
                };
            }
        });
    };
    useEffect(() => {
        getWalletInfo();
        getAccount();
    }, []);

    const theme = useTheme();
    return (
        <Container sx={containerStyle}>
            <SearchBox
                placeholder={'Search...'}
                searchAction={searchTransactions}
            />
            <WalletInfo
                financialSummary={
                    walletFinancialSummary || walletInfoData.financialSummary
                }
                transferActionGroup={walletInfoData.transferActionGroup}
                serviceActionGroup={walletInfoData.serviceActionGroup}
            ></WalletInfo>
            <Box>
                <Typography sx={networkLabelStyle(theme)}>
                    {t('network.title').replace(
                        '%s',
                        theme.rewardAccount.poweredBy,
                    )}
                </Typography>
                <Network />
            </Box>
            <CardListing></CardListing>
            {accountSummaries && accountSummaries.length > 0 && (
                <AccountSummary
                    accountItems={accountSummaries}
                ></AccountSummary>
            )}
            <CardSlider></CardSlider>
            <CashFlow></CashFlow>
            <TransactionList
                pageSize={5}
                isShowPagination={false}
                isShowBtnViewMore={true}
            ></TransactionList>
        </Container>
    );
};

export default Dashboard;

