import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import resourceUrl from 'resourceResolver';
import Button from '../button/Button';
import CardImage from '../card-image/CardImage';
import {
    boxContainerStyle,
    boxWrapperStyle,
    imageStyle,
    successWrapperStyle,
    successfulTitleStyle,
    successfulDescriptionStyle,
} from './AddCardSuccessful.style';
import { useTranslation } from 'react-i18next';

const AddCardSuccessful = ({ response, setIsAddCard }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <Box sx={boxContainerStyle}>
            <>
                {response && (
                    <Box sx={boxWrapperStyle}>
                        <img
                            style={imageStyle}
                            src={resourceUrl('icons/successful-icon.svg')}
                            alt={t('transfer-status.successful')}
                        />
                        <Box sx={successWrapperStyle}>
                            <Typography sx={successfulTitleStyle(theme)}>
                                {t('transfer-status.successful')}
                            </Typography>
                            <Typography sx={successfulDescriptionStyle(theme)}>
                                {t('manage-accounts.card-created-successfully')}
                            </Typography>
                        </Box>
                        <CardImage
                            balance={response.formattedBalance}
                            cardHolder={response.cardHolder}
                            cardNum={response.cardLast4}
                            cardBackgroundImg={response.cardBackground}
                        />
                        <Button
                            label={t('button.ok')}
                            variant="contained"
                            onClick={setIsAddCard}
                        />
                    </Box>
                )}
            </>
        </Box>
    );
};

export default AddCardSuccessful;
