/**
 * @description Define style attribute for Dropdown component.
 */

import { Typography, MenuItem, TextField } from '@mui/material';
import {styled} from "@mui/material/styles";
import {ReactSVG} from "react-svg";

export const components = {
    MuiPaper: {
        defaultProps: {
            sx: {
                borderRadius: '8px',
                background: '#FFF',
                boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.10)',
                marginTop: '8px',
                maxHeight: '360px !important',
                '&::-webkit-scrollbar': {
                    width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#DAE0E3',
                    borderRadius: '99px',
                },
            },
        },
    },
    MuiList: {
        defaultProps: {
            sx: {
                padding: '12px 0',
            },
        },
    },
    MuiMenuItem: {
        defaultProps: {
            sx: {
                height: '50px',
                padding: '16px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '18px',
                display: 'flex',
                gap: '8px',
                background: 'transparent !important',
            },
        },
    },
};

export const StyledTypography = styled(Typography)(({ theme, inputref }) => ({
    '&.MuiTypography-root': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '18px',
        color: theme.appPalette?.grey.grey80,
        width: `calc(${inputref.current?.offsetWidth}px - 96px)`,
    },
}));

export const StyledMenuItem = styled(MenuItem)(({ theme, inputref }) => ({
    '$.MuiMenuItem-root': {
        width: `${inputref.current.offsetWidth}px`,
    },
    '&.MuiMenuItem-root:hover': {
        backgroundColor: theme.appPalette?.primary.primary8 + ' !important',
    },
    '&.MuiMenuItem-root.Mui-selected': {
        backgroundColor: theme.appPalette?.primary.primary8 + ' !important',
        borderLeft: `4px solid ${theme.appPalette?.primary.primary100} !important`,
    },
    '&.MuiSelected:hover': {
        backgroundColor: theme.appPalette?.primary.primary8 + ' !important',
    },
}));

export const StyledTextField = styled(TextField, {
    name: 'StyledTextField',
})(({ theme, icon }) => ({
    width: '100%',
    '& .MuiInputBase-root': {
        height: 50,
        borderRadius: '8px',
        background: '#FFF',
        boxShadow:
            '0px 12px 96px 0px rgba(228, 229, 252, 0.12), 0px 0px 10px 0px rgba(228, 229, 252, 0.50)',
        padding: '0 24px',
        paddingLeft: icon ? '54px' : '24px',
        paddingTop: '4px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiSelect-outlined': {
        padding: 0,
        overflow: 'hidden',
        color: theme.appPalette?.grey.grey80,
        textOverflow: 'ellipsis',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
    },
    '& .MuiFormLabel-root': {
        overflow: 'hidden',
        color: `${theme.appPalette?.grey.grey60} !important`,
        textOverflow: 'ellipsis',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '18px',
        paddingLeft: icon ? '40px' : '10px',
        maxWidth: '80%',
    },
    '& .MuiSvgIcon-root': {
        right: '18px',
    },
    '& .Mui-disabled': {
        background: theme.appPalette?.grey.grey2,
        color: `${theme.appPalette?.grey.grey20} !important`,
    },
    '& .MuiMenuItem-root': {
        '&:hover': {
            background: 'transparent',
        },
    },
    '& .MuiInputBase-root.Mui-focused': {
        background: '#FFF',
        boxShadow: '0px 0px 16px 0px rgba(178, 164, 202, 0.50)',
    },
}));

export const iconStyle = (disabled) => ({
    position: 'absolute',
    zIndex: 10,
    top: '18px',
    left: '24px',
    opacity: disabled ? 0.5 : 1,
});

export const CustomSVG = styled(ReactSVG)(({theme}) => ({
    svg: {
        'path[fill]': {
            fill: theme.appPalette?.grey.grey80
        },
        'path[stroke]': {
            stroke: theme.appPalette?.grey.grey80
        }
    },
    lineHeight: 0
}))
