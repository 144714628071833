/**
 * @description Password component.
 */

import { IconButton, InputAdornment } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import resourceUrl from 'resourceResolver';
import { StyledPassword, CustomSVG } from './Password.style';
import { passwordPropTypes } from 'types/passwordPropTypes';

const CloseIcon = resourceUrl('icons/Close.svg');
const VisibilityIcon = resourceUrl('icons/Visibility.svg');
const VisibilityOffIcon = resourceUrl('icons/VisibilityOff.svg');

const Password = ({
    placeholder,
    onValueChange,
    defaultValue,
    defaultFocus,
    inputProps,
    ...props
}) => {
    const [value, setValue] = useState('');
    const [isFocus, setIsFocus] = useState(false);
    const focusRef = useRef();

    // Apply default focus when the component mount
    useEffect(() => {
        defaultFocus && handleFocus();
    }, [defaultFocus]);

    // Set default value when the component mount
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleFocus = () => {
        focusRef.current.focus();
    };

    const handleChange = (e) => {
        setValue(e.target.value);
        setIsFocus(true);
        onValueChange?.(e.target.value);
    };

    const handleClear = () => {
        setValue('');
        onValueChange?.('');
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsFocus(false);
        }, 200);
    };
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return (
        <StyledPassword
            inputRef={focusRef}
            fullWidth
            value={value}
            onChange={handleChange}
            onFocus={() => setIsFocus(true)}
            onBlur={handleBlur}
            type={showPassword ? 'text' : 'password'}
            InputProps={{
                placeholder,
                endAdornment: (
                    <InputAdornment
                        position="end"
                        sx={{
                            justifyContent: 'center',
                            justifyItems: 'center',
                        }}
                    >
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                            {showPassword ? (
                                <CustomSVG
                                    src={VisibilityIcon}
                                    beforeInjection={(svg) => {
                                        svg.setAttribute('width', '16');
                                        svg.setAttribute('height', '16');
                                    }}
                                />
                            ) : (
                                <CustomSVG
                                    src={VisibilityOffIcon}
                                    beforeInjection={(svg) => {
                                        svg.setAttribute('width', '16');
                                        svg.setAttribute('height', '16');
                                    }}
                                />
                            )}
                        </IconButton>
                    </InputAdornment>
                ),
                ...inputProps,
            }}
            {...props}
        ></StyledPassword>
    );
};

Password.propTypes = {
    ...passwordPropTypes,
};
export default Password;

