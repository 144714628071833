import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import {
    boxNoAccountsFoundStyle,
    imgStyle,
    noAccountsFoundStyle,
} from './NoDataFound.style';
import { noDataFoundPropTypes } from 'types/noDataFoundPropTypes';
const NoDataFound = ({icon, label}) => {
    const theme = useTheme();
    return (
        <Box sx={boxNoAccountsFoundStyle(theme)}>
            <img
                style={imgStyle}
                src={icon}
                alt={label}
            />
            <Typography sx={noAccountsFoundStyle(theme)}>
                {label}
            </Typography>
        </Box>
    );
};

NoDataFound.propTypes = {
    ...noDataFoundPropTypes,
};

export default NoDataFound;
