/**
 * @description statusCode.js file.
 */

const STATUS_CODE = {
    OK: 2009000,
};
export default STATUS_CODE;

export function isSuccess (code) {
    const statusCode = code % 10000;
    
    return (statusCode >= 9000 && statusCode <= 9999);
}
