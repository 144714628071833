/**
 * @description Button component.
 */

import { Button as ButtonMUI, Box } from '@mui/material';
import { boxStyle, StyledCircularProgress, buttonStyle } from './Button.style';
import { buttonPropTypes } from 'types/buttonPropTypes';

const Button = ({
    disabled,
    variant,
    size,
    startIcon,
    endIcon,
    label,
    type,
    onClick,
    loading,
}) => {
    return (
        <ButtonMUI
            disabled={disabled}
            startIcon={startIcon}
            endIcon={endIcon}
            variant={variant}
            type={type}
            onClick={onClick}
            style={{ ...buttonStyle, ...getSize(size), ...getBorder(variant) }}
        >
            <Box sx={boxStyle}>
                {label}
                {loading && <StyledCircularProgress />}
            </Box>
        </ButtonMUI>
    );
};

function getBorder(variant) {
    if (variant === 'outlined') {
        return {
            border: '2px solid',
        };
    }
}

function getSize(size) {
    switch (size) {
        case 'small':
            return {
                minWidth: '100px',
                height: '36px',
            };
        case 'large':
            return {
                minWidth: '160px',
                height: '60px',
            };
        default:
            return {
                minWidth: '140px',
                height: '50px',
            };
    }
}

Button.propTypes = {
    ...buttonPropTypes,
};

Button.defaultProps = {
    disabled: false,
};
export default Button;

