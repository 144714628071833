/**
 * @description CardAccountCustomComboBox component.
 */

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, ThemeProvider, createTheme, useTheme } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import {
    StyledTextField,
    StyledTypography,
    StyledMenuItem,
    components,
    CustomSVG,
} from './CardAccountCustomComboBox.style';
import { cardAccountCustomComboBoxPropTypes } from 'types/cardAccountPropTypes';

const CardAccountCustomComboBox = ({
    options,
    disabled,
    defaultValue,
    label,
    isSvgImage,
    onChangeValue,
}) => {
    const inputRef = useRef();
    const theme = useTheme();
    const customTheme = createTheme({
        ...theme,
        components: components,
    });
    const [value, setValue] = useState('');
    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    return (
        <ThemeProvider theme={customTheme}>
            <StyledTextField
                ref={inputRef}
                label={value === '' ? label : ''}
                InputLabelProps={{ shrink: false }}
                select
                fullWidth
                value={value}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChangeValue(e);
                }}
                SelectProps={{
                    IconComponent: ExpandMoreIcon,
                }}
                disabled={disabled}
            >
                {options &&
                    options.map((item) => (
                        <StyledMenuItem
                            key={item.value}
                            value={item.value}
                            inputref={inputRef}
                        >
                            {isSvgImage && (
                                <CustomSVG
                                    src={item.logo}
                                    disabled={disabled}
                                />
                            )}
                            {!isSvgImage && (
                                <img
                                    width="49"
                                    height="24"
                                    src={item.logo}
                                    alt="logo"
                                />
                            )}
                            <Box>
                                <StyledTypography
                                    inputref={inputRef}
                                    istitle={'true'}
                                >
                                    {item.title}
                                </StyledTypography>
                                <StyledTypography
                                    inputref={inputRef}
                                    istitle={''}
                                >
                                    {item.content}
                                </StyledTypography>
                            </Box>
                        </StyledMenuItem>
                    ))}
            </StyledTextField>
        </ThemeProvider>
    );
};

CardAccountCustomComboBox.propTypes = {
    ...cardAccountCustomComboBoxPropTypes,
};

export default CardAccountCustomComboBox;

