/**
 * @description Input component.
 */

import CancelIcon from '@mui/icons-material/Cancel';
import { InputAdornment, useTheme } from '@mui/material';
import INPUT_FORMAT from 'constants/inputFormat';
import { inputPropTypes } from 'types/inputPropTypes';
import React, { useEffect, useRef, useState } from 'react';
import { StyledTextField, cancelIconStyle } from './Input.style';

const Input = ({
    isDisabled,
    placeholder,
    onValueChange,
    defaultValue,
    defaultFocus,
    name,
    inputProps,
    format,
    readOnly,
}) => {
    const [value, setValue] = useState('');
    const [isFocus, setIsFocus] = useState(false);
    const focusRef = useRef();
    const theme = useTheme();
    // Apply default focus when the component mount
    useEffect(() => {
        defaultFocus && handleFocus();
    }, [defaultFocus]);

    // Set default value when the component mount
    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const handleFocus = () => {
        focusRef.current.focus();
    };

    const handleSetValueChange = (regex, value) => {
        if (regex.test(value) || value === '') {
            setValue(value);
            onValueChange?.(value);
        }
    };

    // format ####-####-####-####-....
    const cardNumberFormat = (value) => {
        value = value.replace(/\D/g, '');
        const cardNumber = value.replace(/(\d{4})(?=\d)/g, '$1-');
        setValue(cardNumber.replace(/-+$/, ''));
        onValueChange?.(cardNumber.replace(/-+$/, ''));
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setIsFocus(true);
        switch (format) {
            case INPUT_FORMAT.ALPHABETICAL_CHARACTER_SPACE.name:
                handleSetValueChange(
                    INPUT_FORMAT.ALPHABETICAL_CHARACTER_SPACE.pattern,
                    value,
                );
                break;
            case INPUT_FORMAT.NUMERIC.name:
                handleSetValueChange(INPUT_FORMAT.NUMERIC.pattern, value);
                break;
            case INPUT_FORMAT.NUMERIC_HYPHEN.name:
                handleSetValueChange(
                    INPUT_FORMAT.NUMERIC_HYPHEN.pattern,
                    value,
                );
                break;
            case INPUT_FORMAT.CARD_NUMBER_FORMAT.name:
                cardNumberFormat(value);
                break;
            default:
                setValue(value);
                onValueChange?.(value);
        }
    };

    const handleClear = () => {
        setValue('');
        onValueChange?.('');
    };

    const handleBlur = () => {
        setTimeout(() => {
            setIsFocus(false);
        }, 200);
    };

    return (
        <StyledTextField
            inputRef={focusRef}
            fullWidth
            value={value}
            onChange={handleChange}
            onFocus={() => setIsFocus(!readOnly)}
            onBlur={handleBlur}
            name={name}
            inputProps={{ readOnly: readOnly }}
            InputProps={{
                placeholder,
                endAdornment: (
                    <InputAdornment position="end">
                        {value && isFocus && (
                            <CancelIcon
                                onClick={handleClear}
                                sx={cancelIconStyle(theme)}
                            />
                        )}
                    </InputAdornment>
                ),
                ...inputProps,
            }}
            disabled={isDisabled}
        ></StyledTextField>
    );
};

Input.propTypes = {
    ...inputPropTypes,
};

export default Input;

