/**
 * @description RoutesContext component.
 */

import React, { createContext, useState, useContext } from 'react';

const RoutesContext = createContext();

export const useRoutes = () => useContext(RoutesContext);

const RoutesProvider = ({ children }) => {
    const [isSubMenu, setIsSubMenu] = useState(true);
    return (
        <RoutesContext.Provider value={{ isSubMenu, setIsSubMenu }}>
            {children}
        </RoutesContext.Provider>
    );
};

export default RoutesProvider;
