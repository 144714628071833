/**
 * @description Define the expected data types for props passed to ToastMessage component.
 */

import { PropTypes } from 'prop-types';

export const toastMessagePropTypes = {
    isSuccess: PropTypes.bool,
    isWarning: PropTypes.bool,
    isInformation: PropTypes.bool,
    isError: PropTypes.bool,
    open: PropTypes.bool,
    handleClose: PropTypes.func,
    title: PropTypes.string,
    content: PropTypes.string,
    maxWidth: PropTypes.string,
}