/**
 * @description Define style attribute for TransferStatus component.
 */
import { styled } from '@mui/material/styles';
import { ReactSVG } from 'react-svg';

export const boxContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '100px',
};

export const boxWrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '29px',
    width: '400px',
    alignItems: 'center',
};

export const boxItemStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '16px',
};

export const boxItemInfoStyle = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: '24px 0',
};

export const flexEndStyle = {
    display: 'flex',
    justifyContent: 'space-between',
};

export const successfulTitleStyle = (theme) => ({
    fontSize: '28px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '34px',
    color: theme.appPalette?.grey.grey80,
});

export const loadedStyle = (theme) => ({
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',
    color: theme.appPalette?.grey.grey60,
});

export const boxItemLoadStyle = (theme) => ({
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '4px',
    borderBottom: `1px solid ${theme.appPalette?.grey.grey12}`,
});

export const titleItemStyle = (theme) => ({
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
    color: theme.appPalette?.grey.grey60,
});

export const valueStyle = (theme) => ({
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '18px',
    color: theme.appPalette?.grey.grey80,
    width: '294px',
    textAlign: 'right',
    wordBreak: 'break-word',
});

export const imageStyle = {
    width: '64px',
    height: '64px',
};

export const boxInformationStyle = (theme) => ({
    display: 'flex',
    padding: '8px 12px',
    gap: '12px',
    alignSelf: 'stretch',
    alignItems: 'center',
    borderRadius: '8px',
    height: '40px',
    border: '1px solid ' + theme.appPalette?.primary.primary100,
    background: theme.appPalette?.primary.primary12,
})

export const descInformationStyle = {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '18px',
}

export const CustomSVG = styled(ReactSVG)(({theme}) => ({
    svg: {
        'path[fill]': {
            fill: theme.appPalette?.primary.primary100
        },
        'path[stroke]': {
            stroke: theme.appPalette?.primary.primary100
        }
    },
    lineHeight: 0
}))