/**
 * @description Sidebar component.
 */

import React, { useEffect, useState } from 'react';
import { Menu, Sidebar as ProSidebar, SubMenu } from 'react-pro-sidebar';
import { Box, useTheme } from '@mui/material';
import SidebarItem from './sidebar-item/SidebarItem';
import PropTypes from 'prop-types';
import resourceUrl from 'resourceResolver';
import PAGE from 'constants/page';
import { useLocation } from 'react-router-dom';
import {
    SvgIcon,
    CustomTooltip,
    menuItemStyles,
    SidebarBox,
    SidebarContentContainer,
    sidebarStyles,
    StyledCollapseButton,
    tooltipProps,
    rootSubMenuStyles,
} from './Sidebar.style';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { TRANSFER_STATE } from 'constants/transferState';
import { useRoutes } from 'contexts/RoutesContext';

const SidebarMenu = ({ items, collapsed, style }) => {
    const location = useLocation();
    const { t } = useTranslation();
    const theme = useTheme();
    const { isSubMenu, setIsSubMenu } = useRoutes();
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    useEffect(() => {
        const activeSubMenu = topSidebar.reduce((acc, curr) => {
            if (curr.subMenus) {
                const foundSubMenu = curr.subMenus.find(
                    (subMenu) => subMenu.navigate === location.pathname,
                );
                if (foundSubMenu) {
                    acc = foundSubMenu;
                }
            }
            return acc;
        }, null);
        if (!activeSubMenu) {
            setIsSubMenu(false);
        }
    }, []);
    return (
        <Menu menuItemStyles={menuItemStyles(collapsed)} style={style}>
            <Box display="flex" flexDirection="column" gap="4px">
                {items.map((item) => {
                    return (
                        <Box key={item.id}>
                            {item.subMenus?.length > 0 ? (
                                <SubMenu
                                    open={
                                        isSubMenu &&
                                        item.subMenus.some(
                                            (submenu) =>
                                                submenu.navigate ===
                                                    location.pathname ||
                                                isOpenMenu,
                                        )
                                    }
                                    key={item.id}
                                    rootStyles={rootSubMenuStyles(theme)}
                                    prefix={item.icon}
                                    suffix={<ExpandMoreIcon />}
                                    label={t(item.title) || ''}
                                    onClick={() => {
                                        setIsSubMenu(!isSubMenu);
                                        setIsOpenMenu(!isOpenMenu);
                                    }}
                                >
                                    {item.subMenus.map((subMenu) => (
                                        <SidebarItem
                                            key={subMenu.id}
                                            title={t(subMenu.title) || ''}
                                            to={subMenu.navigate}
                                            selected={location.pathname.toLowerCase().startsWith(
                                                subMenu.navigate,
                                            )}
                                            state={subMenu.state}
                                        />
                                    ))}
                                </SubMenu>
                            ) : (
                                <Box
                                    onClick={() => {
                                        setIsSubMenu(false);
                                        setIsOpenMenu(false);
                                    }}
                                >
                                    <SidebarItem
                                        key={item.id}
                                        title={t(item.title) || ''}
                                        to={item.navigate}
                                        icon={item.icon}
                                        selected={location.pathname.toLowerCase().startsWith(
                                            item.navigate,
                                        )}
                                        state={item.state}
                                    />
                                </Box>
                            )}
                        </Box>
                    );
                })}
            </Box>
        </Menu>
    );
};

const baseSidebarMenuProps = {
    collapsed: PropTypes.bool.isRequired,
};
SidebarMenu.propTypes = {
    ...baseSidebarMenuProps,
    items: PropTypes.array.isRequired,
    style: PropTypes.object, // Add other prop types as needed
};

// Usage
const TopSidebar = ({ topSidebar, collapsed }) => {
    return <SidebarMenu items={topSidebar} collapsed={collapsed} />;
};

TopSidebar.propTypes = {
    ...baseSidebarMenuProps,
    topSidebar: PropTypes.array.isRequired,
};

const BottomSidebar = ({ bottomSidebar, collapsed }) => {
    return <SidebarMenu items={bottomSidebar} collapsed={collapsed} />;
};

BottomSidebar.propTypes = {
    ...baseSidebarMenuProps,
    bottomSidebar: PropTypes.array.isRequired,
};

const topSidebar = [
    {
        id: 1,
        title: 'sidebar.dashboard',
        icon: <SvgIcon src={resourceUrl('icons/home.svg')} />,
        navigate: PAGE.DASHBOARD,
    },
    {
        id: 2,
        title: 'sidebar.wallet',
        icon: <SvgIcon src={resourceUrl('icons/wallet.svg')} />,
        subMenus: [
            {
                id: 1,
                title: 'wallet-info.load',
                navigate: PAGE.LOAD,
                state: { flow: TRANSFER_STATE.LOAD },
            },
            {
                id: 2,
                title: 'wallet-info.withdraw',
                navigate: PAGE.WITHDRAW,
            },
            {
                id: 3,
                title: 'wallet-info.transfer',
                navigate: PAGE.TRANSFER,
                state: { flow: TRANSFER_STATE.TRANSFER },
            },
        ],
    },
    {
        id: 3,
        title: 'sidebar.add-funds',
        icon: <SvgIcon src={resourceUrl('icons/add-funds.svg')} />,
        navigate: PAGE.ADD_FUNDS,
    },
    {
        id: 4,
        title: 'sidebar.manage-accounts',
        icon: <SvgIcon src={resourceUrl('icons/manage-accounts.svg')} />,
        navigate: PAGE.MANAGE_ACCOUNTS,
    },
];
const bottomSidebar = [
    {
        id: 12,
        title: 'sidebar.log-out',
        icon: <SvgIcon src={resourceUrl('icons/logout.svg')} />,
        navigate: PAGE.LOGOUT,
    },
];

const Sidebar = () => {
    const [collapsed, setCollapsed] = useState(false);
    const theme = useTheme();
    const [displayCollapse, setDisplayCollapse] = useState(false);
    return (
        <SidebarBox
            onMouseEnter={() => setDisplayCollapse(true)}
            onMouseLeave={() => setDisplayCollapse(false)}
        >
            {displayCollapse && (
                <CustomTooltip
                    title="Collapse"
                    placement="right"
                    slotProps={tooltipProps(theme)}
                >
                    <StyledCollapseButton
                        collapsed={collapsed}
                        onClick={() => {
                            setCollapsed(!collapsed);
                        }}
                    >
                        <SvgIcon src={resourceUrl('icons/collapse.svg')} />
                    </StyledCollapseButton>
                </CustomTooltip>
            )}
            <ProSidebar
                width="240px"
                collapsedWidth="72px"
                rootStyles={sidebarStyles}
                transitionDuration={125}
                backgroundColor={theme.appPalette?.grey.grey2}
                collapsed={collapsed}
            >
                <SidebarContentContainer>
                    <TopSidebar topSidebar={topSidebar} collapsed={collapsed} />
                    <BottomSidebar
                        bottomSidebar={bottomSidebar}
                        collapsed={collapsed}
                    />
                </SidebarContentContainer>
            </ProSidebar>
        </SidebarBox>
    );
};

export default Sidebar;

