/**
 * @description Define the expected data types for props passed to Account component.
 */

import PropTypes from "prop-types";

export const accountPropTypes = {
    data: PropTypes.object,
    transferAction: PropTypes.func,
    viewAction: PropTypes.func,
};