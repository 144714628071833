/**
 * @description Withdraw component.
 */

import React, { useState, useEffect } from 'react';
import { Container, Typography, useTheme, Box } from '@mui/material';
import {
    containerStyle,
    titleStyle,
    transferBoxStyle,
    transferBoxItemStyle,
    amountBoxStyle,
    amountTitleStyle,
    amountInputStyle,
    hyperTextStyle,
    arrowIconStyle,
    errorStyle,
    grandTotalStyle,
    grandTotalItemStyle,
    grandTotalTitleStyle,
    boxItemTransferStyle,
    boxBtnSubmit,
} from './Withdraw.style';
import {
    getWithdrawalRequestInfo,
    withdrawalRequest,
} from 'services/payment/paymentService';
import { getCardListing } from 'services/accounting/accountingService';
import { useTranslation } from 'react-i18next';
import { NumericFormat } from 'react-number-format';
import CardAccountCustomComboBox from 'components/card-account-custom-combo-box/CardAccountCustomComboBox';
import Input from 'components/input/Input';
import { useNavigate } from 'react-router-dom';
import CardType from 'constants/cardType';
import { useAuth } from 'hooks/useAuth';
import PAGE from 'constants/page';
import PaymentMethod from 'constants/paymentMethod';
import Button from 'components/button/Button';
import { Controller, useForm } from 'react-hook-form';
import resourceUrl from 'resourceResolver';
import Popup from 'components/pop-up/Popup';
import PageTitle from 'components/page-title/PageTitle';
import Textarea from 'components/textarea/Textarea';
import { isSuccess } from 'constants/statusCode';

const arrowRight = resourceUrl('icons/arrow-right.svg');
const iconCard = resourceUrl('icons/card.svg');
const MEMO_MAX_LENGTH = 200;
const AMOUNT_GREATER_THAN = 0;

const Withdraw = () => {
    const theme = useTheme();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { jwtToken } = useAuth();
    const [isSubmit, setIsSubmit] = useState(false);
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [withdrawalRequestInfo, setWithdrawalRequestInfo] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [bankAccounts, setBankAccounts] = useState([]);
    const [cashAccounts, setCashAccounts] = useState([]);
    const [cashAccountOptions, setCashAccountOptions] = useState([]);
    const [bankAccountOptions, setBankAccountOptions] = useState([]);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const getCardInfoById = (data, id) => {
        return data.find((card) => card.id === id);
    };

    const onBackClick = () => {
        setIsSubmit(false);
        setWithdrawalRequestInfo(null);
    };

    const normalizeCardOptions = (data, setOptions) => {
        let cardOptions = data.map((item) => {
            return {
                title: item.cardName + ' *' + item.cardNum,
                value: item.id,
                logo: iconCard,
            };
        });
        setOptions(cardOptions);
    };

    const getBankAccounts = () => {
        getCardListing(jwtToken, PaymentMethod.BANK, true).then((response) => {
            setBankAccounts(response);
            normalizeCardOptions(response, setBankAccountOptions);
        });
    };

    const getCashAccounts = () => {
        getCardListing(jwtToken, PaymentMethod.CASH_CARD, true).then(
            (response) => {
                setCashAccounts(response);
                normalizeCardOptions(response, setCashAccountOptions);
            },
        );
    };

    useEffect(() => {
        getCashAccounts();
        getBankAccounts();
    }, []);

    const onSubmit = (data) => {
        const remitterCard = getCardInfoById(cashAccounts, data.transferFrom);
        getWithdrawalInfo(data.amount, remitterCard.paymentMethod);
    };

    const getWithdrawalInfo = async (amount, remitterPaymentMethod) => {
        await getWithdrawalRequestInfo(
            jwtToken,
            amount,
            remitterPaymentMethod,
        ).then((response) => {
            if (response && isSuccess(response.code)) {
                setWithdrawalRequestInfo(response.result);
            } else {
                setErrorMsg(response?.message || t('error-message.common'));
                setIsShowPopup(true);
            }
        });
    };

    const onConfirm = async (data) => {
        setIsSubmit(true);
        const remitterInfo = getCardInfoById(cashAccounts, data.transferFrom);
        const beneficiaryInfo = getCardInfoById(bankAccounts, data.transferTo);
        const body = {
            remitterWalletSessionId: remitterInfo.id,
            remitterCardType: remitterInfo.paymentMethod,
            beneficiaryWalletSessionId: beneficiaryInfo.id,
            beneficiaryCardType: beneficiaryInfo.paymentMethod,
            amount: data.amount,
            memo: data.memo,
        };
        const fallbackResponse = {
            transferFrom: remitterInfo.cardName + ' *' + remitterInfo.cardNum,
            transferTo:
                beneficiaryInfo.cardName + ' *' + beneficiaryInfo.cardNum,
        };
        await withdrawalRequest(jwtToken, body, fallbackResponse).then(
            (response) => {
                if (response?.isSuccess) {
                    navigate(PAGE.WITHDRAW_SUCCESSFUL, {
                        state: {
                            ...response,
                            isWithdrawal: true,
                        },
                    });
                } else {
                    setErrorMsg(response?.message || t('error-message.common'));
                    setIsShowPopup(true);
                }
                setIsSubmit(false);
            },
        );
    };

    return (
        <Container sx={containerStyle}>
            {isShowPopup && (
                <Popup
                    open={isShowPopup}
                    content={errorMsg}
                    onClose={() => {
                        setIsShowPopup(false);
                    }}
                    onAgree={() => {
                        setIsShowPopup(false);
                    }}
                />
            )}
            {withdrawalRequestInfo ? (
                <PageTitle
                    title={t('withdraw.withdrawal-confirmation')}
                    onClick={onBackClick}
                />
            ) : (
                <Typography sx={titleStyle}>
                    {t('withdraw.withdrawal-request')}
                </Typography>
            )}
            <form
                onSubmit={handleSubmit(
                    withdrawalRequestInfo ? onConfirm : onSubmit,
                )}
            >
                <Box sx={transferBoxStyle(theme)}>
                    <Box sx={transferBoxItemStyle(isSubmit)}>
                        <Typography>
                            {t('transfer.tab.transfer-money.transfer-from')}
                        </Typography>
                        <Box sx={boxItemTransferStyle}>
                            <Controller
                                name="transferFrom"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <CardAccountCustomComboBox
                                        disabled={
                                            !!withdrawalRequestInfo ||
                                            cashAccountOptions?.length === 0
                                        }
                                        label={t('withdraw.choose-a-cash-card')}
                                        isSvgImage={true}
                                        options={cashAccountOptions}
                                        onChangeValue={(e) =>
                                            field.onChange(e.target.value)
                                        }
                                    />
                                )}
                            />
                            {errors.transferFrom?.type === 'required' && (
                                <Typography sx={errorStyle(theme)}>
                                    {t('validation.required', {
                                        field: t(
                                            'transfer.tab.transfer-money.transfer-from',
                                        ),
                                    })}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box sx={transferBoxItemStyle(isSubmit)}>
                        <Typography>
                            {t('transfer.tab.transfer-money.transfer-to')}
                        </Typography>
                        <Box sx={boxItemTransferStyle}>
                            <Controller
                                name="transferTo"
                                control={control}
                                rules={{
                                    required: true,
                                }}
                                render={({ field }) => (
                                    <CardAccountCustomComboBox
                                        disabled={
                                            !!withdrawalRequestInfo ||
                                            bankAccountOptions?.length === 0
                                        }
                                        label={t(
                                            'withdraw.choose-a-bank-account',
                                        )}
                                        isSvgImage={true}
                                        options={bankAccountOptions}
                                        onChangeValue={(e) =>
                                            field.onChange(e.target.value)
                                        }
                                    />
                                )}
                            />
                            {errors.transferTo?.type === 'required' && (
                                <Typography sx={errorStyle(theme)}>
                                    {t('validation.required', {
                                        field: t(
                                            'transfer.tab.transfer-money.transfer-to',
                                        ),
                                    })}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                </Box>
                <Box sx={amountBoxStyle(theme, isSubmit)}>
                    <Box sx={amountTitleStyle}>
                        <Typography>
                            {t('transfer.tab.transfer-money.amount')}
                        </Typography>
                    </Box>
                    <Box sx={amountInputStyle}>
                        <Controller
                            name="amount"
                            control={control}
                            rules={{
                                required: true,
                                validate: {
                                    largerThanZero: (v) =>
                                        v > AMOUNT_GREATER_THAN,
                                },
                            }}
                            render={({ field }) => (
                                <Input
                                    defaultValue=""
                                    isDisabled={!!withdrawalRequestInfo}
                                    onValueChange={(value) =>
                                        field.onChange(value)
                                    }
                                    placeholder={t(
                                        'transfer.tab.transfer-money.enter-amount',
                                    )}
                                    inputProps={{
                                        inputComponent: NumericFormatCustom,
                                        inputProps: {
                                            prefix: theme.numericFormatted
                                                .prefix,
                                            scale: theme.numericFormatted.scale,
                                        },
                                    }}
                                />
                            )}
                        />
                        <Typography sx={hyperTextStyle(theme)}>
                            {t('transfer.tab.transfer-money.greater-than', {
                                number: AMOUNT_GREATER_THAN,
                            })}
                        </Typography>
                        {errors.amount?.type === 'required' && (
                            <Typography sx={errorStyle(theme)}>
                                {t('validation.required', {
                                    field: t(
                                        'transfer.tab.transfer-money.amount',
                                    ),
                                })}
                            </Typography>
                        )}
                        {errors.amount?.type === 'largerThanZero' && (
                            <Typography sx={errorStyle(theme)}>
                                {t('validation.invalid', {
                                    field: t(
                                        'transfer.tab.transfer-money.amount',
                                    ),
                                })}
                            </Typography>
                        )}
                    </Box>
                </Box>
                {withdrawalRequestInfo && (
                    <Box sx={grandTotalStyle(theme, isSubmit)}>
                        <Box sx={grandTotalItemStyle}>
                            <Typography sx={grandTotalTitleStyle}>
                                {t('transfer.tab.transfer-money.fee')}
                            </Typography>
                            <Typography sx={grandTotalTitleStyle}>
                                {withdrawalRequestInfo.formattedTotalFee}
                            </Typography>
                        </Box>
                        <Box sx={grandTotalItemStyle}>
                            <Typography sx={grandTotalTitleStyle}>
                                {t('transfer.tab.transfer-money.grand-total')}
                            </Typography>
                            <Typography sx={grandTotalTitleStyle}>
                                {withdrawalRequestInfo.formattedGrandTotal}
                            </Typography>
                        </Box>
                    </Box>
                )}
                <Box
                    sx={{
                        ...amountBoxStyle(theme, isSubmit),
                        paddingBottom: '40px',
                    }}
                >
                    <Box sx={amountTitleStyle}>
                        <Typography>
                            {t('transfer.tab.transfer-money.memo-optional')}
                        </Typography>
                    </Box>
                    <Box sx={amountInputStyle}>
                        <Controller
                            name="memo"
                            control={control}
                            rules={{
                                maxLength: MEMO_MAX_LENGTH,
                            }}
                            render={({ field }) => (
                                <Textarea
                                    placeholder={t(
                                        'transfer.tab.transfer-money.memo',
                                    )}
                                    onChange={(value) => field.onChange(value)}
                                    disabled={!!withdrawalRequestInfo}
                                />
                            )}
                        />
                        <Typography sx={hyperTextStyle(theme)}>
                            {t('transfer.tab.transfer-money.max-length', {
                                maxLength: MEMO_MAX_LENGTH,
                            })}
                        </Typography>
                        {errors.memo?.type === 'maxLength' && (
                            <Typography sx={errorStyle(theme)}>
                                {t('validation.max-length', {
                                    maxLength: MEMO_MAX_LENGTH,
                                })}
                            </Typography>
                        )}
                    </Box>
                </Box>
                <Box sx={boxBtnSubmit}>
                    <Button
                        type="submit"
                        variant="contained"
                        disabled={isSubmit}
                        loading={isSubmit}
                        label={
                            withdrawalRequestInfo
                                ? t('withdraw.send')
                                : t('transfer.tab.transfer-money.confirm')
                        }
                        endIcon={
                            !isSubmit && (
                                <img
                                    style={arrowIconStyle}
                                    alt={
                                        withdrawalRequestInfo
                                            ? t('withdraw.send')
                                            : t(
                                                  'transfer.tab.transfer-money.confirm',
                                              )
                                    }
                                    src={arrowRight}
                                />
                            )
                        }
                    />
                </Box>
            </form>
        </Container>
    );
};

const NumericFormatCustom = React.forwardRef(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                allowNegative={false}
                decimalScale={other.scale}
                prefix={other.prefix}
            />
        );
    },
);

export default Withdraw;
