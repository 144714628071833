/**
 * @description Account component.
 */

import { Box, Grid, Paper, Typography, useTheme } from '@mui/material';
import {
    accountBalanceLabelStyle,
    accountBalanceStyle,
    accountInfoStyle,
    mainPanelStyle,
    masterGridStyle,
    presentBalanceGridStyle,
    presentBalanceStyle,
    secondBalanceLabelStyle,
    titleSectionStyle,
} from './Account.style';
import { accountPropTypes } from 'types/accountPropTypes';
import { useTranslation } from 'react-i18next';

const AccountSummaryItem = ({ data, viewAction, transferAction }) => {
    const theme = useTheme();
    const { t } = useTranslation();
    return (
        <>
            <Box>
                <Typography sx={{ ...titleSectionStyle }}>
                    {data.title}
                </Typography>
            </Box>
            <Paper sx={{ ...mainPanelStyle }}>
                <Grid container style={{ ...masterGridStyle }}>
                    <Grid item lg={4} xs={4}>
                        <Typography sx={{ ...accountInfoStyle(theme) }}>
                            {data.accountName} (...{data.last4})
                        </Typography>
                        <Typography sx={{ ...accountBalanceStyle }}>
                            {data.availableBalance}
                        </Typography>
                        <Typography sx={{ ...accountBalanceLabelStyle(theme) }}>
                            {t('account-summary.available-balance')}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        lg={2}
                        xs={2}
                        style={{ ...presentBalanceGridStyle }}
                    >
                        <Typography sx={{ ...presentBalanceStyle(theme) }}>
                            {data.presentBalance}
                        </Typography>
                        <Typography sx={{ ...secondBalanceLabelStyle(theme) }}>
                            {data.secondBalanceLabel}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
};

AccountSummaryItem.propTypes = {
    ...accountPropTypes,
};

export default AccountSummaryItem;

