/**
 * @description CardListing component.
 */

import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Box, Typography, useTheme } from '@mui/material';
import CardImage from 'components/card-image/CardImage';
import resourceUrl from 'resourceResolver';
import { getCardListing } from 'services/accounting/accountingService';
import { useAuth } from 'hooks/useAuth';
import {
    btnStyles,
    btnArrowLeftStyle,
    btnArrowRightStyle,
    carouselItemStyle,
    imgStyle,
    boxContainerStyle,
    titleStyle,
    boxNoAccountsFoundStyle,
    noAccountsFoundStyle,
    containerStyle,
} from './CardListing.style';
import { useTranslation } from 'react-i18next';
import { customArrowPropType } from 'types/customArrowPropType';
import SUBSECTION from 'constants/subsection';
import PAYMENT_METHOD from 'constants/paymentMethod';
const arrowForwardIcon = resourceUrl('icons/arrow-forward.svg');
const arrowBackIcon = resourceUrl('icons/arrow-back.svg');

const responsive = {
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
    },
    tablet: {
        breakpoint: { max: 1024, min: 576 },
        items: 2,
    },
    mobile: {
        breakpoint: { max: 576, min: 0 },
        items: 1,
    },
};

const CustomArrowBack = ({ onClick }) => (
    <button onClick={onClick} style={{ ...btnStyles, ...btnArrowLeftStyle }}>
        <img src={arrowBackIcon} alt="arrow-back" />
    </button>
);
CustomArrowBack.propTypes = {
    ...customArrowPropType,
};

const CustomArrowForward = ({ onClick }) => (
    <button onClick={onClick} style={{ ...btnStyles, ...btnArrowRightStyle }}>
        <img src={arrowForwardIcon} alt="arrow-forward" />
    </button>
);

CustomArrowForward.propTypes = {
    ...customArrowPropType,
};
const kemCardBg = resourceUrl('images/kem-card.svg');
const EBTCardBg = resourceUrl('images/EBT.svg');
const sandDollarCardBg = resourceUrl('images/sand-dollar-card.svg');
const saBg = resourceUrl('images/usd-saving-card.svg');
const unemploymentCard = resourceUrl('images/unemployment-card.png');

const backgroundImages = {
    SAND_DOLLAR: sandDollarCardBg,
    SAVING: saBg,
    1001: kemCardBg,
    1002: unemploymentCard,
    1000: EBTCardBg,
};

const CardSlider = () => {
    const [data, setData] = useState([]);
    const [fetched, setFetched] = useState(false);
    const theme = useTheme();
    const { jwtToken } = useAuth();
    const { t } = useTranslation();
    useEffect(() => {
        getCardListing(
            jwtToken,
            `${PAYMENT_METHOD.CASH_CARD},${PAYMENT_METHOD.SAND_DOLLAR}`,
        ).then((response) => {
            setFetched(true);
            setData(response);
        });
    }, []);
    return (
        <Box sx={boxContainerStyle(theme)}>
            <Typography sx={titleStyle(theme)}>
                {t('card-listing.title')}
            </Typography>
            {(!data || data.length === 0) && fetched ? (
                <Box sx={boxNoAccountsFoundStyle(theme)}>
                    <img
                        style={imgStyle}
                        src={theme.noAccountsFound}
                        alt={t('card-listing.no-card-found')}
                    />
                    <Typography sx={noAccountsFoundStyle(theme)}>
                        {t('card-listing.no-card-found')}
                    </Typography>
                </Box>
            ) : (
                <Box sx={containerStyle(theme)}>
                    <Carousel
                        axis="horizontal"
                        deviceType="desktop"
                        ssr={true}
                        containerClass={
                            data?.length < 3 ? 'carousel-container' : ''
                        }
                        responsive={responsive}
                        customLeftArrow={<CustomArrowBack />}
                        customRightArrow={<CustomArrowForward />}
                    >
                        {data?.map((item) => (
                            <div
                                style={carouselItemStyle}
                                key={item.id}
                                className="card-item"
                            >
                                <CardImage
                                    cardBackgroundImg={
                                        item.ssCategory === SUBSECTION.KASH
                                            ? backgroundImages[
                                                  item.branchCode
                                              ] || theme.cashCardBg
                                            : backgroundImages[item.ssCategory]
                                    }
                                    cardNum={item.cardNum}
                                    cardName={
                                        item.ssCategory === SUBSECTION.SAVING &&
                                        item.cardName
                                    }
                                    cardHolder={item.cardHolder}
                                    balance={item.balance}
                                    cardCode={item.cardCode}
                                />
                            </div>
                        ))}
                    </Carousel>
                </Box>
            )}
        </Box>
    );
};

export default CardSlider;

