/**
 * @description TransferForm component.
 */

import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import resourceUrl from 'resourceResolver';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import Button from 'components/button/Button';
import Input from 'components/input/Input';
import {
    arrowIconStyle,
    containerStyle,
    contentGridStyle,
    CustomSVG,
    iconBackStyle,
    inputDescriptionStyle,
    StyledH4,
    styledH4LineHeight36,
    titleStyle,
    inlineErrorStyle,
    transferBoxStyle,
    transferBoxItemStyle,
    dropdownTransferStyle,
} from 'components/transfer-form/TransferForm.style';
import { buttonStyle } from 'scenes/transfer/Transfer.style';
import CardAccountCustomComboBox from 'components/card-account-custom-combo-box/CardAccountCustomComboBox';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import PAGE from 'constants/page';
import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';
import {
    getCardListing,
    getLinkedAccounts,
} from 'services/accounting/accountingService';
import { useAuth } from 'hooks/useAuth';
import { v4 } from 'uuid';
import {
    getTransferInfo,
    kount,
    transferMoney,
} from 'services/payment/paymentService';
import Popup from '../../components/pop-up/Popup';
import CardType from 'constants/cardType';
import PaymentMethod from '../../constants/paymentMethod';
import { useLocation } from 'react-router-dom';
import { TRANSFER_STATE } from 'constants/transferState';

const arrowRight = resourceUrl('icons/arrow-right.svg');
const iconCard = resourceUrl('icons/card.svg');
const arrowLeft = resourceUrl('icons/arrow-left.svg');

const MEMO_MAX_LENGTH = 200;
const AMOUNT_GREATER_THAN = 0;

const TransferForm = () => {
    const { jwtToken } = useAuth();
    const [cashAccountOptions, setCashAccountOptions] = useState([]);
    const [linkedAccountOptions, setLinkedAccountOptions] = useState([]);
    const { t } = useTranslation();
    const theme = useTheme();
    const navigate = useNavigate();
    const { state } = useLocation();
    const {
        control,
        handleSubmit,
        formState: { errors },
        getValues,
    } = useForm();
    const [sessionId, setSessionId] = useState(null);
    const [transferInfo, setTransferInfo] = useState(null);
    const [linkAccounts, setLinkAccounts] = useState([]);
    const [cashAccounts, setCashAccounts] = useState([]);
    const [isShowPopup, setIsShowPopup] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [isSubmit, setIsSubmit] = useState(false);

    const normalizeCardOptions = (data, setOptions) => {
        let cardOptions = data.map((item) => {
            return {
                title: item.cardName + ' *' + item.cardNum,
                value: item.id,
                logo: iconCard,
            };
        });
        setOptions(cardOptions);
    };

    const getLinkAccounts = () => {
        getLinkedAccounts(jwtToken).then((response) => {
            setLinkAccounts(response);
            normalizeCardOptions(response, setLinkedAccountOptions);
        });
    };

    const getCashAccounts = () => {
        getCardListing(jwtToken, PaymentMethod.CASH_CARD, true).then(
            (response) => {
                setCashAccounts(response);
                normalizeCardOptions(response, setCashAccountOptions);
            },
        );
    };

    useEffect(() => {
        getCashAccounts();
        getLinkAccounts();
    }, []);

    useEffect(() => {
        if (sessionId && transferInfo?.kountUrl) {
            kount.loadEvent(`${transferInfo.kountUrl}&s=${sessionId}`);
        }
    }, [sessionId, transferInfo]);

    const onSubmit = async (data) => {
        const remitterCard = getCardInfoById(getRemitterAccounts(), data.from);
        const newTransferInfo = await enrichTransferInfo(
            data.amount,
            remitterCard.paymentMethod,
        );
        const sessionId = v4().toString().replaceAll(/-/g, '');
        if (newTransferInfo?.kountUrl) {
            await kount.clean(`${newTransferInfo?.kountUrl}`);
        }
        setSessionId(sessionId);
    };

    const getCardInfoById = (data, id) => {
        return data.find((card) => card.id === id);
    };

    function getRemitterAccounts() {
        return !state || state.flow === TRANSFER_STATE.TRANSFER
            ? cashAccounts
            : linkAccounts;
    }

    const onConfirm = async (data) => {
        setIsSubmit(true);
        const remitterInfo = getCardInfoById(getRemitterAccounts(), data.from);
        const beneficiaryInfo = getCardInfoById(cashAccounts, data.to);
        const body = {
            remitterWalletSessionId: remitterInfo.cardToken || remitterInfo.id,
            remitterCardType: remitterInfo.paymentMethod,
            beneficiaryWalletSessionId: beneficiaryInfo.id,
            beneficiaryCardType: beneficiaryInfo.paymentMethod,
            sessionId: sessionId,
            memo: data.memo,
            amount: data.amount,
        };

        const fallbackResponse = {
            transferFrom: remitterInfo.cardName + ' *' + remitterInfo.cardNum,
            transferTo:
                beneficiaryInfo.cardName + ' *' + beneficiaryInfo.cardNum,
            amount: '$' + data.amount,
            fee: transferInfo?.formattedTotalFee,
            memo: data.memo,
            isSuccess: false,
        };
        await transferMoney(jwtToken, body, fallbackResponse).then(
            (response) => {
                if (response?.isSuccess) {
                    navigate(PAGE.TRANSFER_SUCCESSFUL, {
                        state: {
                            ...response,
                        },
                    });
                } else {
                    setErrorMsg(response?.message || t('error-message.common'));
                    setIsShowPopup(true);
                }
                setIsSubmit(false);
            },
        );
    };

    const onBackClick = () => {
        setIsSubmit(false);
        if (sessionId) {
            kount.clean(`${transferInfo?.kountUrl}`);
            setSessionId(null);
            setTransferInfo(null);
        } else {
            navigate(PAGE.DASHBOARD);
        }
    };

    const enrichTransferInfo = async (amount, remitterPaymentMethod) => {
        return getTransferInfo(jwtToken, amount, remitterPaymentMethod).then(
            (data) => {
                setTransferInfo(data);
                return data;
            },
        );
    };

    function getAccountOptions() {
        return !state || state.flow === TRANSFER_STATE.TRANSFER
            ? cashAccountOptions
            : linkedAccountOptions;
    }

    return (
        <Container sx={containerStyle}>
            {isShowPopup && (
                <Popup
                    open={isShowPopup}
                    title={t('transfer-status.failed!')}
                    content={errorMsg}
                    onClose={() => {
                        setIsShowPopup(false);
                    }}
                    onAgree={() => {
                        setIsShowPopup(false);
                    }}
                />
            )}
            <Grid container sx={titleStyle}>
                {sessionId && (
                    <Grid item sx={iconBackStyle}>
                        <CustomSVG src={arrowLeft} onClick={onBackClick} />
                    </Grid>
                )}
                <Grid item>
                    <Typography
                        color={theme.appPalette?.grey.grey80}
                        variant="H2"
                        fontSize="28px"
                        fontWeight="600"
                        lineHeight="34px"
                    >
                        {sessionId
                            ? t('transfer.tab.transfer-money.confirmation')
                            : t('transfer.tab.transfer-money.transfer')}
                    </Typography>
                </Grid>
            </Grid>
            <Box>
                <form onSubmit={handleSubmit(sessionId ? onConfirm : onSubmit)}>
                    <Grid container sx={transferBoxStyle(theme)}>
                        <Grid item sx={transferBoxItemStyle(isSubmit)}>
                            <Grid item xs={6}>
                                <Typography>
                                    {t(
                                        'transfer.tab.transfer-money.transfer-from',
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={dropdownTransferStyle}>
                                <Controller
                                    name="from"
                                    control={control}
                                    rules={{
                                        required: true,
                                    }}
                                    render={({ field }) => (
                                        <CardAccountCustomComboBox
                                            disabled={
                                                !!sessionId ||
                                                getAccountOptions()?.length ===
                                                    0
                                            }
                                            label={t(
                                                'transfer.tab.transfer-money.choose-a-card',
                                            )}
                                            options={getAccountOptions()}
                                            isSvgImage={true}
                                            onChangeValue={(e) => {
                                                field.onChange(e.target.value);
                                            }}
                                        />
                                    )}
                                />
                                {errors.from?.type === 'required' && (
                                    <Typography sx={inlineErrorStyle(theme)}>
                                        {t('validation.required', {
                                            field: t(
                                                'transfer.tab.transfer-money.transfer-from',
                                            ),
                                        })}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item sx={transferBoxItemStyle(isSubmit)}>
                            <Grid item xs={6}>
                                <Typography>
                                    {t(
                                        'transfer.tab.transfer-money.transfer-to',
                                    )}
                                </Typography>
                            </Grid>
                            <Grid item xs={6} sx={dropdownTransferStyle}>
                                <Controller
                                    name="to"
                                    control={control}
                                    rules={{
                                        required: true,
                                        validate: () =>
                                            getValues('from') !==
                                            getValues('to'),
                                    }}
                                    render={({ field }) => (
                                        <CardAccountCustomComboBox
                                            label={t(
                                                'transfer.tab.transfer-money.choose-a-card',
                                            )}
                                            disabled={
                                                !!sessionId ||
                                                cashAccountOptions?.length === 0
                                            }
                                            options={cashAccountOptions}
                                            isSvgImage={true}
                                            onChangeValue={(e) =>
                                                field.onChange(e.target.value)
                                            }
                                        />
                                    )}
                                />
                                <Typography sx={inputDescriptionStyle}>
                                    {t(
                                        'transfer.tab.transfer-money.supported-cash-card',
                                    )}
                                </Typography>
                                {(errors.to?.type === 'required' ||
                                    errors.to?.type === 'validate') && (
                                    <Typography sx={inlineErrorStyle(theme)}>
                                        {t(
                                            `validation.${errors.to?.type === 'required' ? 'required' : 'invalid'}`,
                                            {
                                                field: t(
                                                    'transfer.tab.transfer-money.transfer-to',
                                                ),
                                            },
                                        )}
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container sx={contentGridStyle(theme, isSubmit)}>
                        <Grid item xs={6}>
                            <StyledH4>
                                {t('transfer.tab.transfer-money.amount')}
                            </StyledH4>
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="amount"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: {
                                        largerThanZero: (v) =>
                                            v > AMOUNT_GREATER_THAN,
                                    },
                                }}
                                render={({ field }) => (
                                    <Input
                                        defaultValue=""
                                        isDisabled={!!sessionId}
                                        onValueChange={(value) =>
                                            field.onChange(value)
                                        }
                                        name="amount-input"
                                        placeholder={t(
                                            'transfer.tab.transfer-money.enter-amount',
                                        )}
                                        inputProps={{
                                            inputComponent: NumericFormatCustom,
                                            inputProps: {
                                                prefix: theme.numericFormatted
                                                    .prefix,
                                                scale: theme.numericFormatted
                                                    .scale,
                                            },
                                        }}
                                    />
                                )}
                            />
                            <Typography sx={inputDescriptionStyle}>
                                {t('transfer.tab.transfer-money.greater-than', {
                                    number: AMOUNT_GREATER_THAN,
                                })}
                            </Typography>
                            {(errors.amount?.type === 'required' ||
                                errors.amount?.type === 'largerThanZero') && (
                                <Typography sx={inlineErrorStyle(theme)}>
                                    {t(
                                        errors.amount?.type === 'required'
                                            ? 'validation.required'
                                            : 'validation.invalid',
                                        {
                                            field: t(
                                                'transfer.tab.transfer-money.amount',
                                            ),
                                        },
                                    )}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    {transferInfo && sessionId && (
                        <Grid container sx={contentGridStyle(theme, isSubmit)}>
                            <Grid
                                container
                                justifyContent={'space-between'}
                                item
                            >
                                <StyledH4 sx={styledH4LineHeight36}>
                                    {t('transfer.tab.transfer-money.fee')}
                                </StyledH4>
                                <StyledH4 sx={styledH4LineHeight36}>
                                    {transferInfo.formattedTotalFee}
                                </StyledH4>
                            </Grid>
                            <Grid
                                container
                                justifyContent={'space-between'}
                                item
                            >
                                <StyledH4 sx={styledH4LineHeight36}>
                                    {t(
                                        'transfer.tab.transfer-money.grand-total',
                                    )}
                                </StyledH4>
                                <StyledH4 sx={styledH4LineHeight36}>
                                    {transferInfo.formattedGrandTotal}
                                </StyledH4>
                            </Grid>
                        </Grid>
                    )}
                    <Grid
                        container
                        sx={{
                            ...contentGridStyle(theme, isSubmit),
                            paddingBottom: '40px',
                        }}
                    >
                        <Grid item xs={6}>
                            <StyledH4>
                                {t('transfer.tab.transfer-money.memo-optional')}
                            </StyledH4>
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name="memo"
                                control={control}
                                rules={{
                                    maxLength: MEMO_MAX_LENGTH,
                                }}
                                render={({ field }) => (
                                    <Input
                                        defaultValue=""
                                        isDisabled={!!sessionId}
                                        onValueChange={(value) =>
                                            field.onChange(value)
                                        }
                                        placeholder={t(
                                            'transfer.tab.transfer-money.memo',
                                        )}
                                    />
                                )}
                            />
                            <Typography sx={inputDescriptionStyle}>
                                {t('transfer.tab.transfer-money.max-length', {
                                    maxLength: MEMO_MAX_LENGTH,
                                })}
                            </Typography>
                            {errors.memo?.type === 'maxLength' && (
                                <Typography sx={inlineErrorStyle(theme)}>
                                    {t('validation.invalid', {
                                        field: t(
                                            'transfer.tab.transfer-money.memo',
                                        ),
                                    })}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                    <Grid container item sx={buttonStyle}>
                        <Button
                            type="submit"
                            variant="contained"
                            disabled={isSubmit}
                            loading={isSubmit}
                            label={
                                sessionId
                                    ? t('transfer.tab.transfer-money.confirm')
                                    : t('transfer.tab.transfer-money.submit')
                            }
                            endIcon={
                                !isSubmit && (
                                    <img
                                        style={arrowIconStyle}
                                        alt={
                                            sessionId
                                                ? t(
                                                      'transfer.tab.transfer-money.confirm',
                                                  )
                                                : t(
                                                      'transfer.tab.transfer-money.submit',
                                                  )
                                        }
                                        src={arrowRight}
                                    />
                                )
                            }
                        />
                    </Grid>
                </form>
            </Box>
        </Container>
    );
};

const NumericFormatCustom = React.forwardRef(
    function NumericFormatCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                thousandSeparator
                valueIsNumericString
                allowNegative={false}
                decimalScale={other.scale}
                prefix={other.prefix}
            />
        );
    },
);

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default TransferForm;

