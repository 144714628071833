import { styled } from '@mui/material/styles';
import { Paper } from '@mui/material';
export const StyledPaper = styled(Paper)(({ theme }) => ({
    '&.MuiPaper-root': {
        boxShadow: 'none',
        width: '100%',
    },
    '& .MuiTableCell-head': {
        color: theme.appPalette?.grey.grey60,
        fontWeight: '600',
        fontSize: '12px',
        lineHeight: '14px',
        padding: '0 12px',
        height: '36px',
    },
    '& .MuiTableCell-root': {
        borderBottom: `1px solid ${theme.appPalette?.grey.grey12}`,
    },
    '& .MuiTableCell-body': {
        height: '64px',
        padding: '0 12px',
        fontWeight: 600,
        lineHeight: '18px',
        fontSize: '14px',
        color: theme.appPalette?.grey.grey80,
    },
}));
