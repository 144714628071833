/**
 * @description ProtectedRoute component.
 */

import LOCAL_STORAGE from 'constants/localStorage';
import PAGE from "constants/page";
import { useAuth } from 'hooks/useAuth';
import { useLocalStorage } from 'hooks/useLocalStorage';
import PropTypes from 'prop-types';
import React from 'react';
import { Navigate } from 'react-router-dom';

const protectedRoutePropTypes = {
    children: PropTypes.element,
};
export const ProtectedRoute = ({ children }) => {
    const { jwtToken } = useAuth();
    const [otpExpireDate, setOtpExpireDate] = useLocalStorage(
        LOCAL_STORAGE.OTP_EXPIRE_DATE,
    );
    const [otpNonce, setOtpNonce] = useLocalStorage(LOCAL_STORAGE.OTP_NONCE);

    if (otpExpireDate) {
        const currentTimestamp = Date.now();
        const difference = Math.floor(
            (otpExpireDate - currentTimestamp) / 1000,
        );
        if (difference < 0) {
            setOtpExpireDate(null);
            setOtpNonce(null);
            return <Navigate to={PAGE.LOGIN} />;
        }
    }
    const isLogin =
        React.Children.toArray(children).filter((x) => x.type.name === 'Login')
            .length === 1;
    if (!jwtToken && !otpNonce && !isLogin) {
        return <Navigate to={PAGE.LOGIN} />;
    }
    if (!jwtToken && otpNonce && !isLogin) {
        return <Navigate to={PAGE.OTP} />;
    } else if (jwtToken && isLogin) {
        return <Navigate to={PAGE.DASHBOARD} />;
    }
    return children;
};
ProtectedRoute.propTypes = {
    ...protectedRoutePropTypes,
};
