/**
 * @description apiClient.js file.
 */

import Axios from 'axios';
import Api from 'configs/api';
import { resolveTenant } from 'tenantResolver';
import i18n from 'i18n';

const client = Axios.create({
    baseURL: Api.BASE_URL,
    responseType: 'json',
    headers: Object.assign({
        'x-tenant': resolveTenant(),
        deviceId: 'VISIKARD-XXXX-XXXX-WEB',
    }),
    timeout: 30 * 1000, // 10s
});

function updateAcceptLanguageHeader() {
    if (
        client.defaults &&
        client.defaults.headers &&
        client.defaults.headers.common
    ) {
        client.defaults.headers.common['Accept-Language'] = i18n.language;
    }
}

updateAcceptLanguageHeader();

i18n.on('languageChanged', () => {
    updateAcceptLanguageHeader();
});

const ApiClient = {
    post: async (path, payload, customHeader = {}) => {
        try {
            const headers = {
                ...customHeader,
            };
            const response = await client.post(path, payload, { headers });
            return response.data;
        } catch (error) {
            console.error('Error occurred:', error);
            throw error;
        }
    },
    get: async (path, data) => {
        try {
            const response = await client.get(path, data);
            return response.data;
        } catch (error) {
            console.error('Error occurred:', error);
            throw error;
        }
    },
};

export default ApiClient;

