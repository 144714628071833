/**
 * @description CashFlow component.
 */

import React, { useEffect, useState } from 'react';
import { Typography, LinearProgress, Box, useTheme, Link } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { getCashFlow } from 'services/accounting/accountingService';
import { useAuth } from 'hooks/useAuth';
import { subDays, formatISO } from 'date-fns';
import {
    commonStyle,
    borderRadius,
    linearProgressMoneyOutStyle,
    linearProgressMoneyInStyle,
    imageStyle,
    containerStyle,
    wrapperStyle,
    boxStyle,
    boxItemStyle,
    boxItem2Style,
    boxEmptyStyle,
    threeDotsStyle,
    titleMoneyStyle,
    balanceStyle,
    emptyStyle,
    titleStyle,
} from './CashFlow.style';
import { useTranslation } from 'react-i18next';

const type = {
    CASH_IN: 'CashIn',
    CASH_OUT: 'CashOut',
};

const CashFlow = () => {
    const [data, setData] = useState([]);
    const theme = useTheme();
    const { jwtToken } = useAuth();
    const [fetched, setFetched] = useState(false);
    const { t } = useTranslation();

    useEffect(() => {
        const date = new Date();
        const dateMinus1 = subDays(date, 1);
        const isoStringMinus1 = formatISO(dateMinus1, {
            representation: 'complete',
        });
        const dateMinus30 = subDays(date, 30);
        const isoStringMinus30 = formatISO(dateMinus30, {
            representation: 'complete',
        });
        getCashFlow(jwtToken, isoStringMinus30, isoStringMinus1).then(
            (response) => {
                setFetched(true);
                setData(response);
            },
        );
    }, []);

    return (
        <Box sx={containerStyle}>
            <>
                {(data || data?.length > 0) &&
                    fetched &&
                    data.map((item) => (
                        <Box key={item.type} sx={{ width: '50%' }}>
                            {item.type === type.CASH_IN && (
                                <Box sx={wrapperStyle}>
                                    <Box
                                        sx={{
                                            ...boxStyle,
                                            ...(item.summaries.length === 0
                                                ? { height: 'auto' }
                                                : {}),
                                        }}
                                    >
                                        <Typography sx={titleMoneyStyle(theme)}>
                                            {t('cash-flow.money-in')}
                                        </Typography>
                                        <Typography sx={balanceStyle(theme)}>
                                            {item.formattedTotalAmount}
                                        </Typography>
                                        {item.summaries.length === 0 && (
                                            <Box sx={boxEmptyStyle}>
                                                <img
                                                    src={theme.noPersonsFound}
                                                    style={imageStyle}
                                                    alt={t('cash-flow.there-are-no-remitters')}
                                                />
                                                <Typography sx={emptyStyle(theme)}>
                                                {t('cash-flow.there-are-no-remitters')}
                                                </Typography>
                                            </Box>
                                        )}
                                        {item.summaries.length > 0 && (
                                            <Box sx={boxItemStyle}>
                                                {item.summaries.map((val) => (
                                                    <Box
                                                        key={val.userId}
                                                        sx={boxItem2Style}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                ...titleStyle(theme),
                                                                ...threeDotsStyle,
                                                            }}
                                                        >
                                                            {val.name}
                                                        </Typography>
                                                        <Box sx={commonStyle}>
                                                            <LinearProgress
                                                                variant="buffer"
                                                                value={0}
                                                                valueBuffer={
                                                                    val.valueProgress
                                                                }
                                                                sx={[
                                                                    linearProgressMoneyInStyle,
                                                                    borderRadius,
                                                                ]}
                                                            />
                                                            <Typography
                                                                sx={titleStyle(theme)}
                                                            >
                                                                {
                                                                    val.formattedAmount
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            )}
                            {item.type === type.CASH_OUT && (
                                <Box sx={wrapperStyle}>
                                    <Box
                                        sx={{
                                            ...boxStyle,
                                            ...(item.summaries.length === 0
                                                ? { height: 'auto' }
                                                : {}),
                                        }}
                                    >
                                        <Typography sx={titleMoneyStyle(theme)}>
                                        {t('cash-flow.money-out')}
                                        </Typography>
                                        <Typography sx={balanceStyle(theme)}>
                                            {item.formattedTotalAmount}
                                        </Typography>
                                        {item.summaries.length === 0 && (
                                            <Box sx={boxEmptyStyle}>
                                                <img
                                                    src={theme.noPersonsFound}
                                                    style={imageStyle}
                                                    alt={t('cash-flow.there-are-no-beneficiaries')}
                                                />
                                                <Typography sx={emptyStyle(theme)}>
                                                {t('cash-flow.there-are-no-beneficiaries')}
                                                </Typography>
                                            </Box>
                                        )}
                                        {item.summaries.length > 0 && (
                                            <Box sx={boxItemStyle}>
                                                {item.summaries.map((val) => (
                                                    <Box
                                                        key={val.userId}
                                                        sx={boxItem2Style}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                ...titleStyle(theme),
                                                                ...threeDotsStyle,
                                                            }}
                                                        >
                                                            {val.name}
                                                        </Typography>
                                                        <Box sx={commonStyle}>
                                                            <LinearProgress
                                                                variant="buffer"
                                                                value={0}
                                                                valueBuffer={
                                                                    val.valueProgress
                                                                }
                                                                sx={[
                                                                    linearProgressMoneyOutStyle,
                                                                    borderRadius,
                                                                ]}
                                                            />
                                                            <Typography
                                                                sx={titleStyle(theme)}
                                                            >
                                                                {
                                                                    val.formattedAmount
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                ))}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    ))}
            </>
        </Box>
    );
};

export default CashFlow;

