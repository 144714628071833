/**
 * @description Define the expected data types for props passed to Button component.
 */

import PropTypes from "prop-types";

export const buttonPropTypes = {
    disabled: PropTypes.bool,
    isPrimary: PropTypes.bool,
    label: PropTypes.string.isRequired,
    type: PropTypes.string,
};