/**
 * @description Define the expected data types for props passed to Password component.
 */

import { PropTypes } from 'prop-types';

export const passwordPropTypes = {
    placeholder: PropTypes.string,
    onValueChange: PropTypes.func,
    defaultValue: PropTypes.string,
    defaultFocus: PropTypes.bool,
};
