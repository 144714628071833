/**
 * @description Define style attribute for ActionItem component.
 */

import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material/styles';

export const CustomSVG = styled(ReactSVG)(({ theme }) => ({
    svg: {
        'path[fill]': {
            fill: theme.appPalette?.grey.grey80,
        },
        'path[stroke]': {
            stroke: theme.appPalette?.grey.grey80,
        },
    },
    lineHeight: 0,
    width: '40px',
    height: '40px',
}));

export const textActionItem = (theme) => ({
    color: theme.appPalette?.grey.grey80,
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '18px',
    textAlign: 'center',
});

export const imgGridStyle = (theme) => ({
    height: '40px',
});

export const itemContainer = (theme) => ({
    '&:hover': { cursor: 'pointer' },
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
});
