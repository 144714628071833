export const boxNameStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
};

export const boxLoadingStyle = {
    display: 'flex',
    justifyContent: 'center',
    height: '204px',
    alignItems: 'center',
};
