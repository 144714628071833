/**
 * @description Textarea component.
 */

import React, { useEffect, useRef } from 'react';
import { textareaPropTypes } from 'types/textareaPropTypes';
import { StyledTextarea } from './Textarea.style';

const Textarea = ({ placeholder, onChange, disabled, defaultValue, focus }) => {
    const textareaRef = useRef();

    useEffect(() => {
        focus && textareaRef.current?.focus();
    }, [focus]);

    return (
        <StyledTextarea
            ref={textareaRef}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            defaultValue={defaultValue}
        />
    );
};

Textarea.propTypes = {
    ...textareaPropTypes,
};

export default Textarea;
