/**
 * @description Define the expected data types for props passed to Input component.
 */

import PropTypes from 'prop-types';

export const inputPropTypes = {
    isDisabled: PropTypes.bool,
    placeholder: PropTypes.string,
    onValueChange: PropTypes.func,
    defaultValue: PropTypes.string,
    defaultFocus: PropTypes.bool,
    name: PropTypes.string,
};