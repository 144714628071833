/**
 * @description I18nProvider component.
 */

import { useEffect } from 'react';
import LocalStorageService from 'services/local-storage/localStorageService';
import { getLanguages } from 'services/profile/profileService';
import { useTranslation, I18nextProvider} from 'react-i18next';
import I18n from 'i18n';

const I18nProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const userBrowserLanguage =
        LocalStorageService.getUserBrowerLanguage() || '';

    useEffect(() => {
        const language =
            navigator.language.split('-')[0] ||
            navigator.userLanguage.split('-')[0];
        if (userBrowserLanguage !== language) {
            getLanguages().then((response) => {
                const isLanguageSupported = response.some(
                    (lng) => lng.code === language,
                );
                const tenantBaseLanguage = response.find((lng) => lng.isBase);

                if (isLanguageSupported) {
                    i18n.changeLanguage(language);
                } else if (!isLanguageSupported && tenantBaseLanguage) {
                    i18n.changeLanguage(tenantBaseLanguage.code);
                }

                LocalStorageService.setUserBrowerLanguage(language);
            });
        }
    }, []);
    return <I18nextProvider i18n={I18n}>{children}</I18nextProvider>;
};

export default I18nProvider;
