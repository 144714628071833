/**
 * @description Define style attribute for EmptyPage component.
 */

import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const boxConatinerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100vh',
    gap: '40px',
};

export const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: '34px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '40px',
    color: theme.appPalette?.grey.grey80,
}));

export const imgStyle = {
    width: '931px',
    height: '429px',
};
