/**
 * @description Dropdown component.
 */

import React, { useEffect, useState, useRef } from 'react';
import { createTheme, ThemeProvider, useTheme, Box } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { dropdownPropTypes } from 'types/dropdownPropTypes';
import {
    components,
    StyledMenuItem,
    StyledTypography,
    StyledTextField,
    iconStyle,
    CustomSVG,
} from './Dropdown.style';

const Dropdown = ({
    options,
    disabled,
    defaultValue,
    label,
    onChangeValue,
    icon,
}) => {
    const inputRef = useRef();
    const [value, setValue] = useState('');
    const theme = useTheme();
    const customTheme = createTheme({
        ...theme,
        components: components
    })
    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    return (
        <ThemeProvider theme={customTheme}>
            <Box sx={{ width: '100%', position: 'relative' }}>
                {icon && <CustomSVG src={icon} style={iconStyle(disabled)} />}
                <StyledTextField
                    ref={inputRef}
                    label={value === '' ? label : ''}
                    InputLabelProps={{ shrink: false }}
                    select
                    fullWidth
                    value={value}
                    onChange={(e) => {
                        setValue(e.target.value);
                        onChangeValue(e.target.value);
                    }}
                    SelectProps={{
                        IconComponent: ExpandMoreIcon,
                    }}
                    icon={icon}
                    disabled={disabled}
                >
                    {options &&
                        options.map((item) => (
                            <StyledMenuItem
                                key={item.value}
                                value={item.value}
                                inputref={inputRef}
                            >
                                <StyledTypography inputref={inputRef}>
                                    {item.text}
                                </StyledTypography>
                            </StyledMenuItem>
                        ))}
                </StyledTextField>
            </Box>
        </ThemeProvider>
    );
};

Dropdown.propTypes = {
    ...dropdownPropTypes,
};

export default Dropdown;

