/**
 * @description Define the expected data types for props passed to PageTitle component.
 */

import { PropTypes } from 'prop-types';

export const pageTitlePropTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    isSubmit: PropTypes.string,
};
