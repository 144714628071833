/**
 * @description Define the expected data types for props passed to CardImage component.
 */

import PropTypes from "prop-types";

export const cardImagePropTypes = {
    cardType: PropTypes.string,
    cardNum: PropTypes.string,
    cardEmail: PropTypes.string,
    cardHolder: PropTypes.string,
    cardExpiry: PropTypes.string,
    cardName: PropTypes.string,
    balance: PropTypes.string,
    cardNetwork: PropTypes.string,
    cardCode: PropTypes.string,
};
