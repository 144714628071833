/**
 * @description AccountSummary component.
 */

import { Box } from '@mui/material';
import AccountSummaryItem from 'components/account-summary/account/Account';
import {boxItemStyle} from "components/account-summary/AccountSummary.style";
import {accountSummaryPropTypes} from "types/accountSummaryPropTypes";

const AccountSummary = ({accountItems}) => {
    return (
        <Box>
            {accountItems?.map((accountItem) => (
                <Box key={accountItem.id} sx={boxItemStyle}>
                    <AccountSummaryItem
                        data={accountItem.data}
                        viewAction={accountItem.viewAction}
                        transferAction={accountItem.transferAction}>
                    </AccountSummaryItem>
                </Box>
            ))}
        </Box>
    );
}

AccountSummary.propTypes = {
    ...accountSummaryPropTypes,
};

export default AccountSummary;
