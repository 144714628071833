/**
 * @description Define style attribute for Pagination component.
 */

import { Pagination } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledPagination = styled(Pagination)(({ theme }) => ({
    '& .MuiPaginationItem-root': {
        color: theme.appPalette?.grey.grey80,
    },
    '& .Mui-disabled': {
        color: theme.appPalette?.grey.grey20,
        opacity: '1 !important',
    },
    '& .MuiPaginationItem-root.Mui-selected': {
        backgroundColor: theme.appPalette?.primary.primary12,
        '&:hover': {
            backgroundColor: theme.appPalette?.primary.primary12,
        },
    },
    '& .MuiButtonBase-root:hover': {
        backgroundColor: theme.appPalette?.primary.primary8,
    },
}));
