/**
 * @description Define the expected data types for props passed to SideBarItem component.
 */

import { PropTypes } from 'prop-types';

export const sideBarItemPropTypes = {
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    icon: PropTypes.object,
    selected: PropTypes.bool,
};
