import { styled } from '@mui/material/styles';
import { CircularProgress } from '@mui/material';

export const StyledCircularProgress = styled(CircularProgress)(({ theme }) => ({
    '&.MuiCircularProgress-root': {
        width: '52px !important',
        height: '52px !important',
        color: theme.appPalette?.primary.primary100,
    },
}));
