/**
 * @description localStorageKey.js file.
 */

const LOCAL_STORAGE_KEYS = {
    JWT_TOKEN: 'jwtToken',
    JWT_EXPIRE_DATE: 'jwtExpireDate',
    OTP_NONCE: 'otpNonce',
    OTP_EXPIRE_DATE: 'otpExpireDate',
    SEARCH: 'search',
    USER_BROWSER_LANGUAGE: 'userBrowserLanguage',
};

export default LOCAL_STORAGE_KEYS;
