/**
 * @description tenantResolver.js file.
 */

import { Configuration } from './configs/environment';

export const resolveTenant = () => {
    const currentDomain = window.location.host;

    // Find the corresponding tenant code for the current domain
    const matchedTenant = Configuration.tenants.find(
        (tenant) => currentDomain.indexOf(tenant.domain) !== -1,
    );

    if (matchedTenant) {
        return matchedTenant.code;
    } else {
        console.log(
            'No matching tenant found for the current domain:',
            currentDomain,
        );
    }
};
