/**
 * @description Define style attribute for Header component.
 */

export const headerStyle = (theme) => ({
    width: '100%',
    height: '60px',
    background: theme.appPalette.background.header,
    overflow: 'hidden',
    padding: '8px 28px',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 1300,
    boxShadow: theme.headerShadow,
});
export const logoStyle = (theme) => ({
    width: theme.logoWidth,
    height: 'auto',
});