/**
 * @description paymentMethod.js file.
 */

const PAYMENT_METHOD = {
    CREDIT_CARD: 'CREDIT_CARD',
    CASH_CARD: 'CASH_CARD',
    SAND_DOLLAR: 'SAND_DOLLAR',
    BANK: 'BANK',
};

export default PAYMENT_METHOD;
