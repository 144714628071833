/**
 * @description Define style attribute for CardAccountCustomComboBox component.
 */

import { MenuItem, TextField, Typography } from '@mui/material';
import { ReactSVG } from 'react-svg';
import { styled } from '@mui/material/styles';

export const StyledTextField = styled(TextField, {
    name: 'StyledTextField',
})(({ theme }) => ({
    width: '100%',
    '& .MuiInputBase-root': {
        height: 50,
        borderRadius: '8px',
        background: '#FFF',
        boxShadow:
            '0px 12px 96px 0px rgba(228, 229, 252, 0.12), 0px 0px 10px 0px rgba(228, 229, 252, 0.50)',
        padding: '0 16px',
    },
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none',
    },
    '& .MuiSelect-outlined': {
        padding: 0,
        overflow: 'hidden',
        color: '#1F1F30',
        textOverflow: 'ellipsis',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
    },
    '& .MuiFormLabel-root': {
        overflow: 'hidden',
        color: `${theme.appPalette?.grey.grey60} !important`,
        textOverflow: 'ellipsis',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '18px',
        paddingLeft: '10px',
        maxWidth: '80%',
    },
    '& .MuiSvgIcon-root': {
        right: '18px',
    },
    '& .Mui-disabled': {
        background: theme.appPalette?.grey.grey8,
        color: `${theme.palette.action.disabled} !important`,
    },
    '& .MuiMenuItem-root': {
        '&:hover': {
            background: 'transparent',
        },
    },
    '& .MuiInputBase-root.Mui-focused': {
        background: '#FFF',
        boxShadow: '0px 0px 16px 0px rgba(164, 175, 202, 0.50)',
    },
    '& .MuiSelect-select': {
        whiteSpace: 'unset !important',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
    },
    '& .MuiSelect-select .MuiTypography-root': {
        display: '-webkit-box',
        WebkitBoxOrient: 'vertical',
        WebkitLineClamp: '1',
    },
    '& .MuiPaper-root': {
        borderRadius: '8px',
        background:
            'linear-gradient(0deg, rgba(239, 239, 253, 0.10) 0%, rgba(239, 239, 253, 0.20) 100%), #FFF',
        boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.10)',
        marginTop: '8px',
        maxHeight: '360px !important',
        '&::-webkit-scrollbar': {
            width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DAE0E3',
            borderRadius: '99px',
        },
    },
}));

export const StyledTypography = styled(Typography)(
    ({ theme, inputref, istitle }) => ({
        '&.MuiTypography-root': {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: istitle ? '14px' : '12px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: istitle ? '18px' : '14px',
            color: istitle
                ? theme.appPalette?.grey.grey80
                : theme.appPalette?.grey.grey60,
            width: `calc(${inputref.current?.offsetWidth}px - 96px)`,
        },
    }),
);

export const StyledMenuItem = styled(MenuItem)(({ theme, inputref }) => ({
    '$.MuiMenuItem-root': {
        width: `${inputref.current.offsetWidth}px`,
    },
    '&.MuiMenuItem-root:hover': {
        backgroundColor: theme.appPalette?.primary.primary8 + ' !important',
    },
    '&.MuiMenuItem-root.Mui-selected': {
        backgroundColor: theme.appPalette?.primary.primary8 + ' !important',
        borderLeft: `4px solid ${theme.palette.primary.main} !important`,
    },
    '&.MuiSelected:hover': {
        backgroundColor: theme.appPalette?.primary.primary8 + ' !important',
    },
}));

export const components = {
    MuiPaper: {
        defaultProps: {
            sx: {
                borderRadius: '8px',
                background:
                    'linear-gradient(0deg, rgba(239, 239, 253, 0.10) 0%, rgba(239, 239, 253, 0.20) 100%), #FFF',
                boxShadow: '0px 4px 30px 0px rgba(0, 0, 0, 0.10)',
                marginTop: '8px',
                maxHeight: '360px !important',
                '&::-webkit-scrollbar': {
                    width: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#DAE0E3',
                    borderRadius: '99px',
                },
            },
        },
    },
    MuiList: {
        defaultProps: {
            sx: {
                padding: '12px 0',
            },
        },
    },
    MuiMenuItem: {
        defaultProps: {
            sx: {
                height: '50px',
                padding: '16px',
                overflow: 'hidden',
                color: '#1F1F30',
                textOverflow: 'ellipsis',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '18px',
                display: 'flex',
                gap: '8px',
                background: 'transparent !important',
            },
        },
    },
};

export const CustomSVG = styled(ReactSVG)(({ theme, disabled }) => ({
    svg: {
        'path[fill]': {
            fill: disabled
                ? theme.appPalette?.grey.grey60
                : theme.appPalette?.grey.grey80,
        },
        'path[stroke]': {
            fill: disabled
                ? theme.appPalette?.grey.grey60
                : theme.appPalette?.grey.grey80,
        },
    },
    lineHeight: 0,
}));
