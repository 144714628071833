/**
 * @description transferState.js file.
 */

export const TRANSFER_STATE = {
    LOAD: 'load',
    TRANSFER: 'transfer',
    WITHDRAW: 'withdraw',
};

