/**
 * @description ShoppingNetworkItem component.
 */

import { Grid, useTheme } from '@mui/material';
import React from 'react';
import OverflowTip from '../tooltip/OverflowTip';
import {
    componentStyle,
    contentContainerStyle,
    contentHeaderStyle,
    contentStyle,
    imageContainerStyle,
    imageStyle,
    titleStyle,
    tooltipStyle,
} from './ShoppingNetworkItem.style';
import { shoppingNetworkItemPropTypes } from 'types/shoppingNetworkItemPropTypes';

const ShoppingNetworkItem = ({ title, contentHeader, content, imagePath }) => {
    const theme = useTheme();

    return (
        <Grid container item sx={componentStyle(theme)}>
            <Grid container item xs={8.5}>
                <OverflowTip
                    title={title}
                    textStyle={titleStyle(theme)}
                ></OverflowTip>
                <div style={contentContainerStyle(theme)}>
                    <div>
                        <OverflowTip
                            title={contentHeader}
                            textStyle={contentHeaderStyle(theme)}
                        ></OverflowTip>
                    </div>
                    <div>
                        <OverflowTip
                            title={content}
                            tooltipStyle={tooltipStyle(theme)}
                            textStyle={contentStyle(theme)}
                        ></OverflowTip>
                    </div>
                </div>
            </Grid>
            <Grid container item xs={3.5} sx={imageContainerStyle}>
                <img src={imagePath} alt="imageItem" style={imageStyle} />
            </Grid>
        </Grid>
    );
};

ShoppingNetworkItem.propTypes = {
    ...shoppingNetworkItemPropTypes,
};

export default ShoppingNetworkItem;

