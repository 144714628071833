/**
 * @description DatePicker component.
 */

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import PropTypes from 'prop-types';
import * as React from 'react';
import resourceUrl from 'resourceResolver';
import { useTheme } from '@mui/material';
import { StyleDatePicker, CustomSVG, slotProps } from './DatePicker.style';
const calendarIcon = resourceUrl('images/Calendar.svg');

function MuiIcon() {
    return <CustomSVG src={calendarIcon} />;
}
const DatePicker = ({
    onChange,
    disabled,
    defaultValue,
    readOnly,
    format,
    views,
    ...props
}) => {
    const handleOnChange = (value) => {
        onChange(value);
    };
    const theme = useTheme();

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StyleDatePicker
                disabled={disabled}
                format={format}
                views={views}
                slots={{
                    openPickerIcon: MuiIcon,
                }}
                defaultValue={defaultValue}
                slotProps={{
                    ...slotProps(theme),
                    textField: {
                        readOnly: readOnly,
                    },
                }}
                onChange={(newValue) => handleOnChange(newValue)}
            />
        </LocalizationProvider>
    );
};

DatePicker.propTypes = {
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    defaultValue: PropTypes.object,
    value: PropTypes.object,
};
export default DatePicker;

