/**
 * @description RowsPerPage component.
 */

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    Box,
    ThemeProvider,
    Typography,
    createTheme,
    useTheme,
} from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    StyledTextField,
    components,
    StyledMenuItem,
    boxContainerStyle,
    typographyStyle,
    fontWeightBold,
} from './RowsPerPage.style';
import { rowsPerPagePropTypes } from 'types/rowsPerPagePropTypes';

const RowsPerPage = ({
    defaultValue,
    rowsPerPage,
    totalRecords,
    firstRecordOfPage,
    lastRecordOfPage,
    onValueChange,
}) => {
    const [value, setValue] = useState('');
    const [records, setRecords] = useState('');
    const [first, setFirst] = useState(null);
    const [last, setLast] = useState(null);
    const theme = useTheme();

    const customTheme = createTheme({
        ...theme,
        components: components,
    });

    useEffect(() => {
        if (defaultValue) {
            setValue(defaultValue);
        }
    }, [defaultValue]);

    useEffect(() => {
        if (totalRecords) {
            setRecords(totalRecords);
        }
    }, [totalRecords]);

    useEffect(() => {
        if (firstRecordOfPage) {
            setFirst(firstRecordOfPage);
        }
    }, [firstRecordOfPage]);

    useEffect(() => {
        if (lastRecordOfPage) {
            setLast(lastRecordOfPage);
        }
    }, [lastRecordOfPage]);

    const handleChange = (e) => {
        setValue(e.target.value);
        if (onValueChange) {
            onValueChange(e.target.value);
        }
    };
    const { t } = useTranslation();
    return (
        <ThemeProvider theme={customTheme}>
            <Box sx={boxContainerStyle}>
                <Typography sx={typographyStyle}>
                    {t('pagination.rows-per-page')}
                </Typography>
                <StyledTextField
                    select
                    value={value}
                    onChange={handleChange}
                    SelectProps={{
                        IconComponent: ExpandMoreIcon,
                    }}
                    disabled={totalRecords < rowsPerPage[0]}
                >
                    {rowsPerPage &&
                        rowsPerPage.map((page) => (
                            <StyledMenuItem key={page} value={page}>
                                <Typography
                                    sx={{
                                        ...typographyStyle,
                                        ...fontWeightBold,
                                    }}
                                >
                                    {page}
                                </Typography>
                            </StyledMenuItem>
                        ))}
                </StyledTextField>
                {first && last && (
                    <Typography sx={typographyStyle}>
                        {first}-{last} of {records}
                    </Typography>
                )}
                {!first && !last && (
                    <Typography sx={typographyStyle}>
                        {1}-
                        {totalRecords < rowsPerPage[0] ? totalRecords : value}{' '}
                        of {records}
                    </Typography>
                )}
            </Box>
        </ThemeProvider>
    );
};

RowsPerPage.propTypes = {
    ...rowsPerPagePropTypes,
};

export default RowsPerPage;

